import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '@shared/lib/store';
import styles from './cityAvg.module.scss';
import { deliverySliceSelectors } from '../../model/slice';

interface IDeliveryCityAvg {
  priceFrom: number;
  priceTo: number;
  orderMin: number;
  currency: string;
  timeFrom: string;
  timeTo: string;
}

function DeliveryCityAvg({
  priceFrom,
  priceTo,
  orderMin,
  currency,
  timeFrom,
  timeTo,
}: IDeliveryCityAvg) {
  const { deliveryData, deliveryError } = useAppSelector(
    deliverySliceSelectors.selectDeliveryState
  );

  const [isDelivery, setIsDelivery] = useState<boolean | null>(null);
  const [deliveryTime, setDeliveryTime] = useState<number>(60);
  useEffect(() => {
    if (deliveryError) {
      setIsDelivery(false);
      return;
    }
    if (!deliveryData) return;
    setIsDelivery(true);
    setDeliveryTime(deliveryData.avg_delivery_time);
  }, [deliveryData, deliveryError]);

  const { t } = useTranslation();

  const poligonsFailed = false;

  const cityDeliveryPriceFrom = `${t('CityAvg.before')} ${
    Number.isInteger(priceFrom) ? priceFrom : '50'
  }`;
  const cityDeliveryPriceTo = `${t('CityAvg.to')} ${
    Number.isInteger(priceTo) ? priceTo : '100'
  }${currency}`;
  const cityOrderMin = `${t('CityAvg.before')} ${
    Number.isInteger(orderMin) ? orderMin : '0'
  }${currency}`;

  const isDeliveryTimeEqual = timeFrom === timeTo;

  return (
    <div className={styles.deliveryCityAvg}>
      {isDelivery === false ? (
        <p className={styles.noDelivery}>
          {t('CityAvg.Do not delivery to your address')}
        </p>
      ) : (
        <>
          <div className={styles.infoRow}>
            <span>{t('localization.Delivery worktime')}</span>
            {isDeliveryTimeEqual ? (
              <span>{t('CityAvg.24 hours')}</span>
            ) : (
              <span>
                {timeFrom} &ndash; {timeTo}
              </span>
            )}
          </div>
          <div className={styles.infoRow}>
            <span>{t('localization.Minimal order sum')}</span>
            <span>{cityOrderMin}</span>
          </div>
          <div className={styles.infoRow}>
            <span>{t('localization.Delivery price')}</span>
            {poligonsFailed ? (
              <span className={styles.poligons}>
                {t('CityAvg.delivery_fr')}
              </span>
            ) : (
              <span>
                {cityDeliveryPriceFrom} {cityDeliveryPriceTo}
              </span>
            )}
          </div>
          <div className={styles.infoRow}>
            <span>{t('localization.Delivery time')}</span>
            <span>
              {deliveryTime} {t('CityAvg.min')}
            </span>
          </div>
        </>
      )}
    </div>
  );
}

export default DeliveryCityAvg;
