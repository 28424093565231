import { clsx } from 'clsx';
import { DetailedHTMLProps, HTMLAttributes, PropsWithChildren } from 'react';
import styles from './styles.module.scss';

interface IPageTitleProps
  extends DetailedHTMLProps<
    HTMLAttributes<HTMLHeadingElement>,
    HTMLHeadingElement
  > {
  isCenterOnMobile?: boolean;
}

export function PageTitle({
  children,
  className,
  isCenterOnMobile = false,
}: PropsWithChildren<IPageTitleProps>) {
  const style = clsx(styles.pageTitle, className, {
    [styles.isCenterOnMobile]: isCenterOnMobile,
  });

  return <h1 className={style}>{children}</h1>;
}
