import './Info.scss';

import { useState } from 'react';
import axios from 'axios';
import * as session from 'modules/session';
import { useTranslation } from 'react-i18next';
import UserGifts from 'frontend/Components/UserGifts/UserGifts';
import { UserPromoCode } from '@entities/promocode';
import { Button } from '@shared/ui/button';
import Radio from '@shared/ui/Radio/Radio';
import { userEmitter, userProfileEmitter } from 'modules/event-emitters';
import { analytics } from 'frontend/analytics/analytics';
import { formaterBirthday } from '@shared/lib/date';
import { Subtitle } from '@shared/ui/titles';
import { BlockContainer } from '@shared/ui/content-containers';
import { LastOrders } from '@entities/user';
import { ReorderModal } from 'frontend/Components/Modals/ReorderModal';
import Favorites from '../../Favorites/Favorites';
import AddressEditModal from './Modals/AddressEdit';
import AddressDeleteModal from './Modals/AddressDelete';
import Emitter from '../../NewCartOrder/Emitters';
import { useReorderHandler } from '../model/useReorderHandler';

export function Info({ addresses = [] }) {
  const { user } = session.get() || {};
  const [checked, setChecked] = useState(user?.address_id || null);
  const [isNotEmptyUserGifts, setIsNotEmptyUserGifts] = useState(true);
  useReorderHandler();
  const { t } = useTranslation();

  const editHandler = (e) => {
    if (e) e.preventDefault();
    document.body.scrollTop = 0;
    userProfileEmitter.emit('User.Profile.ChangeTypeAction', 'edit');
  };

  const logoutHandler = () => {
    axios
      .post('/auth/logout')
      .then(() => {
        session.set('user', undefined);
        userEmitter.emit('User.Change');
        userEmitter.emit('User.Logout.Event');
      })
      .catch((error) => {
        console.log('logout request failed');
        console.log(error);
        return false;
      });
    return true;
  };

  const checkedHandler = ({ target }) => {
    const { id } = target;
    const { user = {} } = session.get();
    setChecked(id);

    axios
      .post('/user/set_addresses', { id })
      .then(() => {
        user.address_id = id;
        analytics.addressAction();
      })
      .catch(() => {
        if (user.address_id) delete user.address_id;
      });
  };

  const addressEdit = (address) => {
    userProfileEmitter.emit('User.Address.Edit', address);
  };

  const addressDelete = (address) => {
    userProfileEmitter.emit('User.Address.Delete', address);
  };

  const openDeleteUserModal = () => {
    Emitter.emit('MODAL_CALL', { modal: 'DeleteUserModal' });
  };

  return (
    <>
      <BlockContainer childrenPosition="start">
        <div className="profile__name-and-birthday">
          <div className="input-container">
            <label>{t('Info.name')}</label>
            <p className="classToParagraph">
              {user.name ? user.name : t('Info.name_not_specified')}
            </p>
          </div>
          <div className="input-container">
            <label>{t('Info.birthday')}</label>
            <p className="classToParagraph">
              {user.birth_day
                ? formaterBirthday(user.birth_day)
                : t('Info.birthday_not_specified')}
            </p>
          </div>
        </div>
        <Button onClick={editHandler}>{t('Info.edit')}</Button>
      </BlockContainer>
      {isNotEmptyUserGifts && (
        <BlockContainer>
          <Subtitle>{t('BlockProducts.user_gift')}</Subtitle>
          <UserGifts isExact setIsNotEmptyUserGifts={setIsNotEmptyUserGifts} />
        </BlockContainer>
      )}
      <UserPromoCode />
      {addresses.length > 0 && (
        <BlockContainer>
          <label className="user-profile__address-label">
            {t('Info.main_address')}
          </label>
          <div>
            {addresses.map((address) => (
              <Radio
                id={address.id}
                key={address.id}
                addClass="user-profile__radio"
                checked={+address.id === +checked}
                name={address.id}
                onChange={checkedHandler}
              >
                {address.address}
                {address.title ? (
                  <p className="user-profile__radio__title">{address.title}</p>
                ) : undefined}
                <div className="user-profile__radio__edit">
                  <button type="button" onClick={() => addressEdit()}>
                    {t('Info.edit')}
                  </button>
                  <button
                    type="button"
                    className="user-profile__radio__delete"
                    onClick={() => addressDelete(address)}
                  >
                    {t('Info.delete')}
                  </button>
                </div>
              </Radio>
            ))}
          </div>
        </BlockContainer>
      )}

      <div className="profile__action-container">
        <button type="button" className="btn" onClick={logoutHandler}>
          {t('Info.exit')}
        </button>
        <button type="button" className="btn" onClick={openDeleteUserModal}>
          {t('Info.deleteUser')}
        </button>
      </div>
      <LastOrders numberOfLastOrders={5} />
      <BlockContainer>
        <Subtitle>{t('Info.Favorites')}</Subtitle>
        <Favorites />
      </BlockContainer>
      <ReorderModal />
      <AddressEditModal />
      <AddressDeleteModal />
    </>
  );
}
