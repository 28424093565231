import './superSelect.scss';
import { clsx } from 'clsx';
import { useCheckCountries } from '@entities/countries';

function SuperSelect({ value, onChange, options }) {
  const isUkraine = useCheckCountries();
  return (
    <div className="super-select-container">
      <ul className={clsx('options', { ua: isUkraine })}>
        {options.map((o) => {
          if (!value) value = options[0];
          return (
            <li
              className={clsx({ selected: o === value })}
              key={o}
              onClick={() => onChange(o)}
            >
              {o}
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default SuperSelect;
