import globalStore from 'modules/global-store';
import { useTranslation } from 'react-i18next';
import { Button, LinkButton } from '@shared/ui/button';
import { useForgetComplectationModal } from '@features/modals/comlectation-forget';
import { useNextHandler } from '../lib/useNextHandler';

const getCurrentInfo = () => {
  const {
    user,
    cart: { total = 0 },
  } = globalStore.get('session') || {};
  const {
    delivery: { order_min: orderMin },
  } = globalStore.get('city_info');
  const {
    available_for_order: availableForOrder,
    auth_before_ordering: needToAuth,
    is_delivery: isDeliveryInCity,
    is_pickup: isPickupInCity,
  } = globalStore.get('current_city');

  const isUser = user && Object.keys(user).length;

  const isMinAmount = total < orderMin;

  const isDisableBtnPickup = !availableForOrder || !isPickupInCity;
  const isDisableBtnDelivery =
    isMinAmount || !isDeliveryInCity || !availableForOrder;
  return {
    isUser,
    isDisableBtnPickup,
    isDisableBtnDelivery,
    needToAuth,
  };
};

function ButtonPlaceOrder({
  isDelivery,
  isDisabledProducts,
  isConfirmed,
  setConfirmed,
}: {
  isDelivery: boolean;
  isDisabledProducts: boolean;
  isConfirmed: boolean;
  setConfirmed: () => void;
}) {
  const modal = useForgetComplectationModal();
  const { t } = useTranslation();
  const { isUser, isDisableBtnPickup, isDisableBtnDelivery, needToAuth } =
    getCurrentInfo();
  const text = isDelivery ? t('NewCart.delivery') : t('NewCart.pickup');
  const isDisabledInCity = isDelivery
    ? isDisableBtnDelivery
    : isDisableBtnPickup;

  // Определяем обязательное действие до перехода на следующий шаг оформления
  // (авторизация / удаление недоступных продуктов)
  const handler = useNextHandler({
    isLoginNeed: !isUser && needToAuth,
    isDelivery,
    isDisabledProducts,
  });

  if (isDisabledInCity) {
    return <Button disabled>{text}</Button>;
  }

  const routeUrl = isDelivery ? `/cart/delivery/` : `/cart/pickup/`;

  // Если есть только платная комплектация - при нажатии кнопки показываем модалку про комплектацию
  // На кнопку "Продолжить" вешаем обязательное действие перед оформлением
  if (!isConfirmed) {
    return (
      <Button
        onClick={() =>
          modal.show({
            routeUrl,
            nextHandler: handler,
            closeModal: setConfirmed,
          })
        }
      >
        {text}
      </Button>
    );
  }

  // Если нет вопроса про комплектацию, но есть обязательное действие
  if (handler) {
    return <Button onClick={handler}>{text}</Button>;
  }

  // Если все ОК
  return <LinkButton href={routeUrl}>{text}</LinkButton>;
}

export default ButtonPlaceOrder;
