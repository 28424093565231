import { useTranslation } from 'react-i18next';
import styles from './vacancyCommentLine.module.scss';

function VacancyCommentLine() {
  const { t } = useTranslation();

  return (
    <div className={styles.commentLine}>
      &laquo;{t(`VacancyCommentLine.comment`)}
      &raquo;&nbsp;&ndash;&nbsp;
      <span className={styles.author}>{t(`VacancyCommentLine.author`)}</span>
    </div>
  );
}

export default VacancyCommentLine;
