import { useTranslation } from 'react-i18next';
import { ExternalImage } from '@shared/ui/image';
import { SmartLink } from '@shared/ui';
import { useSelectCountryMainDomain } from '@entities/countries';
import { Media } from '@shared/lib/media';
import { useSelectMenu } from '@entities/menu';
import { Breadcrumbs } from '@shared/ui/breadcrumbs';
import { PageContainer } from '@shared/ui/content-containers';
import { PageTitle } from '@shared/ui/titles';
import { Banner } from '@widgets/seoPage';
import styles from './styles.module.scss';
import CustomText from '../../../Components/CustomText/CustomText';
import CustomHelmet from '../../../Components/CustomHelmet';

export function Menu() {
  const { t } = useTranslation();
  const menu = useSelectMenu();
  const mainDomain = useSelectCountryMainDomain();
  return (
    <>
      <Banner pathname="/menu" />
      <CustomHelmet title={t('localization.Menu')} />
      <PageContainer>
        <Breadcrumbs
          links={[
            {
              text: t('localization.Menu'),
            },
          ]}
        />
        <PageTitle isCenterOnMobile>{t('localization.Menu')}</PageTitle>
        <div className={styles.root}>
          {menu
            .filter(({ visible }) => !!visible)
            .map((item) => {
              const imgProps = {
                mainDomain,
                alt: item.title,
                width: 350,
                height: 280,
              };
              return (
                <SmartLink
                  href={`/menu/${item.text_id}`}
                  className={styles.item}
                  key={item.id}
                >
                  <Media greaterThanOrEqual="notebook">
                    {(classNames, renderProps) => {
                      return renderProps ? (
                        <ExternalImage
                          className={classNames}
                          src={item.images_desktop?.[0]?.filename}
                          {...imgProps}
                        />
                      ) : null;
                    }}
                  </Media>
                  <Media lessThan="notebook">
                    {(classNames, renderProps) => {
                      return renderProps ? (
                        <ExternalImage
                          className={classNames}
                          src={item.images_mobile?.[0]?.filename}
                          {...imgProps}
                        />
                      ) : null;
                    }}
                  </Media>
                  <span className={styles.title}>{item.title}</span>
                </SmartLink>
              );
            })}
        </div>
        <CustomText />
      </PageContainer>
    </>
  );
}
