import { Route } from 'react-router-dom';
import { PageContainer } from '@shared/ui/content-containers';
import ErrorComponent from '../../Components/ErrorComponent/ErrorComponent';

function Status({ code, children }) {
  return (
    <Route
      render={({ staticContext }) => {
        if (staticContext) staticContext.status = code;
        return children;
      }}
    />
  );
}
function ErrorPage() {
  return (
    <Status code={404}>
      <PageContainer>
        <ErrorComponent />
      </PageContainer>
    </Status>
  );
}

export default ErrorPage;
