import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { InternalImage } from '@shared/ui/image';
import { SmartLink } from '@shared/ui';
import { useDownloadAppsModal } from '@features/modals/download-apps-modal';
import { useVideoModal } from '@features/video-modal';
import CameraPopcornSodaImage from './assets/camera.png';
import StarImage from './assets/star.png';
import { DynamicImageByLang } from './dynamic-image-by-Lang/DynamicImageByLang';

interface IBtnContainer
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  link: string;
  handleClick: (link: string) => void;
}

function BtnContainer({
  children,
  link,
  handleClick,
  ...props
}: IBtnContainer) {
  return (
    <button type="button" onClick={() => handleClick(link)} {...props}>
      {children}
    </button>
  );
}

function LinkContainer({
  link,
  className,
  children,
}: PropsWithChildren<{
  link: string;
  className: string;
}>) {
  return (
    <SmartLink className={className} href={link}>
      {children}
    </SmartLink>
  );
}

export const useDesktopScheme = () => {
  const { t } = useTranslation();
  const downloadAppsModal = useDownloadAppsModal();
  const modal = useVideoModal();

  const items = [
    {
      name: 'youtube_embed',
      title: t('Main.watch_title'),
      description: t('localization.companyName'),
      imageSrc: CameraPopcornSodaImage,
      imageAlt: t('Main.camera_popcorn_soda'),
      Container: BtnContainer,
      Img: InternalImage,
      handleClick: (link: string) =>
        modal.show({
          videoLink: `//www.youtube.com/embed/${link}`,
          title: t('Main.watch_title'),
        }),
    },
    {
      name: 'download',
      title: t('Main.download'),
      description: t('Main.application'),
      Container: BtnContainer,
      Img: DynamicImageByLang,
      imageSrc: '',
      imageAlt: t('Main.star_king_with_ice_cream'),
      handleClick: () => downloadAppsModal.show(),
    },
    {
      name: 'bonus',
      title: t('Main.get'),
      description: t('Main.bonuses'),
      Container: LinkContainer,
      Img: InternalImage,
      imageSrc: StarImage,
      imageAlt: t('Main.star_king_with_ice_cream'),
      handleClick: () => {},
    },
  ];

  const findbyNameDesktop = (name: string) =>
    items.find((item) => item.name === name);

  return { findbyNameDesktop };
};

export const useMobileScheme = () => {
  const { t } = useTranslation();

  const items = [
    {
      name: 'youtube_link',
      title: t('Main.watch_title'),
      description: t('localization.companyName'),
      imageSrc: CameraPopcornSodaImage,
      imageAlt: t('Main.camera_popcorn_soda'),
      Img: InternalImage,
      Container: LinkContainer,
    },
    {
      name: 'bonus',
      title: t('Main.get'),
      description: t('Main.bonuses'),
      imageSrc: StarImage,
      imageAlt: t('Main.star_king_with_ice_cream'),
      Img: InternalImage,
      Container: LinkContainer,
    },
  ];

  const findbyNameMobile = (name: string) =>
    items.find((item) => item.name === name);

  return { findbyNameMobile };
};
