import { baseApi } from '@shared/api';
import { errorCodes } from './errorCodes';
import {
  IDeliveryCheckOk,
  IDeliveryCheckParams,
  IDeliveryCheckStopList,
  IDeliveryError,
} from '../model/types';

interface IErrors {
  errors: { open_at?: number[]; order_min?: number[]; products?: number[] };
}

interface ISuccess {
  delivery: IDeliveryCheckOk | IDeliveryCheckStopList;
}

interface IResponse {
  code: number;
  result: string;
  message?: string;
  data?: ISuccess | IErrors;
}

const isSuccess = (data: ISuccess | IErrors): data is ISuccess => {
  return !!('delivery' in data);
};

const responseHandling = (
  response: IResponse
): IDeliveryCheckOk | IDeliveryError | IDeliveryCheckStopList => {
  const { code, data } = response;
  if (!data) return { type: 'not_delivery' };
  if (response.code === 200 && isSuccess(data)) {
    return data.delivery as IDeliveryCheckOk | IDeliveryCheckStopList;
  }

  const { errors } = data as IErrors;
  if (!errors) return { type: 'not_delivery' };
  const { order_min, open_at, products } = errors;
  if (code === errorCodes.open_at && open_at)
    return { type: 'open_at', data: open_at };
  if (code === errorCodes.min_order && order_min)
    return { type: 'min_order', data: order_min };
  if (code === errorCodes.product_on_stop && products)
    return { type: 'product_on_stop', data: products };
  return { type: 'not_delivery' };
};

const errorHandling = () => {
  return { type: 'not_delivery' };
};

export const deliveryCheckApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    deliveryCheck: build.query<
      IDeliveryCheckOk | IDeliveryCheckStopList | IDeliveryError,
      IDeliveryCheckParams
    >({
      query: (params) => {
        return {
          url: `/endpoint/order2/delivery_check`,
          params,
        };
      },
      transformResponse: responseHandling,
      transformErrorResponse: errorHandling,
    }),
  }),
});

export const { useDeliveryCheckQuery } = deliveryCheckApi;
