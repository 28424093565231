import productHelper from 'modules/helpers/product-helper';
import globalStore from 'modules/global-store';
import TextBlock from 'frontend/Components/NewCartOrder/Components/CartProductsBlock/TextBlock/TextBlock';
import { ExternalImage } from '@shared/ui/image';
import { openCardModal } from 'frontend/Components/NewCartOrder/Components/CartProductsBlock/utils';
import PlusMinusButton from 'frontend/Components/NewCartOrder/Components/PlusMinusButton/PlusMinusButton';
import { useSelectCountryMainDomain } from '@entities/countries';
import style from './CartProduct.module.scss';
import './CartProduct.scss';

function CartProduct({ cartItem, currency, isExact }) {
  const mainDomain = useSelectCountryMainDomain();
  const renderTagsIcons = (tags = []) => {
    const tagClassNames = {
      1: 'hot',
      6: 'vegetarian',
    };
    tags = tags.length ? tags.filter((tag) => tag.visible) : [];
    return tags.length ? (
      <div className="icons-container">
        {tags.map((tag, index) => {
          const tagClassName = tagClassNames[tag.tag_id]
            ? tagClassNames[tag.tag_id]
            : '';
          return (
            <i
              key={`icon-${tag.tag_id}${index}`}
              className={`icon-${tagClassName}`}
            />
          );
        })}
      </div>
    ) : undefined;
  };
  const product = productHelper.getCartProduct(cartItem.product_id) || {};
  let { tags = [] } = product;
  const {
    images = [],
    title = '',
    is_action,
    is_delivery,
    is_pickup = true,
    id: productId,
  } = product;
  const { customImage } = globalStore.get('products', [])[productId] || {};
  if (customImage) {
    product.customImage = customImage;
  }
  const { child = [], is_action: isAction, id, discount_rule_id } = cartItem;
  tags = tags.length ? tags.filter((tag) => tag.visible) : [];

  return (
    <div
      onClick={(event) => {
        openCardModal(event, { product, cartItemId: id });
      }}
      className="cart-products__card-row"
    >
      <ExternalImage
        src={`${images[0]?.filename}/100x100`}
        mainDomain={mainDomain}
        alt={title}
        className={style.imgWrapper}
        width={100}
        height={100}
      />
      <div
        className={!isExact ? 'prime-container with-qty' : 'prime-container'}
      >
        {tags.length ? renderTagsIcons(cartItem) : null}
        <div className="text-container">
          <TextBlock
            title={title}
            child={child}
            is_action={is_action}
            is_pickup={is_pickup}
            is_delivery={is_delivery}
            productType={product.product_type}
          />
          <div className="text-container__price">
            <strong className={is_action || isAction ? 'price-is-action' : ''}>
              {cartItem.sum}&nbsp;{currency}
            </strong>
            {cartItem.sum_orig &&
            cartItem.sum_orig > cartItem.sum &&
            (is_action || isAction || discount_rule_id) ? (
              <span>
                {cartItem.sum_orig}&nbsp;{currency}
              </span>
            ) : undefined}
          </div>
        </div>
        {!isExact ? (
          <div className="count-container">x {cartItem.qty}</div>
        ) : (
          <PlusMinusButton
            product={product}
            child={child}
            position="cart_product"
          />
        )}
      </div>
    </div>
  );
}

export default CartProduct;
