import { useSelectCountryMainDomain } from '@entities/countries';
import { useCityAndLang } from '@shared/lib/use-city-and-lang';
import { useSeoPageQuery } from '../model/api';
import { PageBanner } from './page-banner/page-banner';

export function BannerWrapper({ pathname }: { pathname: string }) {
  const { city, lang } = useCityAndLang();
  const mainDomain = useSelectCountryMainDomain();

  const { data, isSuccess } = useSeoPageQuery({
    city,
    lang,
    url: pathname,
  });

  if (!isSuccess) {
    return null;
  }
  return <PageBanner bannerData={data.data.banner} mainDomain={mainDomain} />;
}
