import { clsx } from 'clsx';
import { useTranslation } from 'react-i18next';
import { IBadge, BadgeType } from 'types/product';
import { formatToTime } from '@shared/lib/date';
import style from './timeBadge.module.scss';
import { Timer } from './Timer';

interface ITimeBadgeProps {
  badge: IBadge;
  locale: string;
}

export function TimeBadge({ badge, locale }: ITimeBadgeProps) {
  const { t } = useTranslation();
  const { type, action_from, action_to, show_web } = badge;

  if (!Object.keys(badge).length || !show_web) return null;

  const getPeriod = () =>
    `${formatToTime(action_from)}-${formatToTime(action_to)}`;

  if (type === BadgeType.Period) {
    return (
      <div className={clsx(style.badge, style.period)}>
        {`${t('Card.badge_only')} ${getPeriod()}`}
      </div>
    );
  }
  if (type === BadgeType.Timer) {
    return (
      <Timer
        classNames={clsx(style.badge, style.timer)}
        action_to={action_to}
        locale={locale}
      />
    );
  }
  return null;
}
