import { clsx } from 'clsx';
import styles from './cross.module.scss';

function Cross({ className }: { className: string }) {
  return (
    <>
      <span
        style={{ transform: 'rotate(45deg)' }}
        className={clsx(styles.root, className)}
      />
      <span
        style={{ transform: 'rotate(-45deg)' }}
        className={clsx(styles.root, className)}
      />
    </>
  );
}

export default Cross;
