import '../Card.scss';
import '../CardMobile.scss';
import FavoritesControl from 'frontend/Components/Favorites/favorites-control/FavoritesControl';
import { memo } from 'react';
import { ExternalImage } from '@shared/ui/image';
import { clsx } from 'clsx';
import { useSelectCountryMainDomain } from '@entities/countries';
import { AvailableInfo, TimeBadge } from '@entities/product';
import { ToppingsButton } from '@features/toppings-modal';
import { useSelectCity } from '@entities/cities';
import NeedPromocodeInfo from '../../Components/NeedPromocodeInfo/NeedPromocodeInfo';
import CardIsAction from '../CardIsAction';
import Tags from './tags/Tags';
import styles from './pureCard.module.scss';
import NewMark from './new-mark/NewMark';

const getImageSizes = (type) => ({
  mobile: {
    width: type === 'single' ? 300 : 150,
    height: type === 'single' ? 300 : 150,
  },
  tablet: {
    width: 300,
    height: 300,
  },
  desktop: {
    width: 500,
    height: 500,
  },
});

function PureCard({
  isDelivery,
  product,
  view_type,
  composition,
  superCardButtons,
  deliveryChangeButtons,
  additionalPrice,
  price,
  buyBtn,
}) {
  const { locale } = useSelectCity();
  const mainDomain = useSelectCountryMainDomain();

  const {
    product_type: { name } = {},
    customHtml,
    specialStyle,
    specialStyle: { card = {}, titleStyle = {} } = {},
    title,
    available,
    available_from,
    available_to,
    decoration = {},
    badge,
  } = product || {};
  const isBigCard = name === 'longcard';
  const isToppings = !!(
    product.toppings &&
    product.toppings.length &&
    product.max_toppings !== 0
  );
  const isComposition = product.composition && product.composition.length;
  const isBlack = specialStyle === 'Black';
  const isRed = specialStyle === 'Red';
  const {
    name_color: nameColor = '',
    card_color: cardColor = '',
    image: badgeSrc = '',
    color_border_cart = '',
    color_border_cart_hover = '',
    color_text_cart_hover = '',
    color_text_cart = '',
  } = decoration;

  const onHover = (event) => {
    event.preventDefault();
    event.target.style.color = color_text_cart_hover;
    event.target.style.borderColor = color_border_cart_hover;
  };

  const onMouseLeave = (event) => {
    event.preventDefault();
    event.target.style.color = color_text_cart;
    event.target.style.borderColor = color_border_cart;
  };

  const cardView = clsx(`card--${view_type}`, {
    'card--black': isBlack,
    'card--common-1': isRed,
  });
  const priceAndByuButton = clsx('card__price__and__buybutton', {
    'big-card': isBigCard,
  });
  const availableInfoGrid =
    view_type === 'grid' ? styles.availableInfoGrid : '';

  return (
    <>
      {customHtml && (
        <div
          className="custom-wrapper"
          dangerouslySetInnerHTML={{ __html: customHtml }}
        />
      )}
      <div
        className={cardView}
        style={{
          ...card,
          backgroundColor: cardColor,
        }}
      >
        {badgeSrc ? (
          <ExternalImage
            mainDomain={mainDomain}
            className={styles.customBadge}
            alt="custom-badge"
            src={`/${badgeSrc}.svg`}
          />
        ) : (
          <NewMark isNewProduct={product.is_new} view_type={view_type} />
        )}

        <FavoritesControl product={product} view_type={view_type} />
        {isBigCard ? (
          <div className="card__title--big-card">{title}</div>
        ) : null}
        <div className={clsx(styles.imageContainer, { 'big-card': isBigCard })}>
          <ExternalImage
            mainDomain={mainDomain}
            className={clsx(styles.imgWrapper, {
              [styles.grid]: view_type === 'grid',
            })}
            src={product.images[0]?.filename}
            title={title}
            sizes={getImageSizes(view_type)}
            width={400}
            height={400}
          />
          <TimeBadge badge={badge} locale={locale} />
        </div>
        {isBigCard ? (
          <div className="card__title--big-card">{title}</div>
        ) : null}
        <div className="card__main-content">
          {!isBigCard ? (
            <div className="card__title-content">
              <p
                itemScope
                itemType="https://schema.org/name"
                className="card__name"
                style={{ ...titleStyle, color: nameColor }}
              >
                {title}
              </p>
              <Tags tags={product.tags} />
            </div>
          ) : null}
          {view_type !== 'recomend' && (
            <>
              <div className="card__more-content">
                {!superCardButtons && isDelivery}
                <CardIsAction
                  product={product}
                  addComma={isComposition}
                  view_type={view_type}
                />
                {composition}
              </div>
              <AvailableInfo
                available={available}
                available_from={available_from}
                available_to={available_to}
                className={availableInfoGrid}
              />
            </>
          )}

          <NeedPromocodeInfo product={product} />
        </div>
        <div className={priceAndByuButton}>
          {isToppings && view_type !== 'recomend' && (
            <ToppingsButton product={product} />
          )}
          {superCardButtons}
          {deliveryChangeButtons}
          <div className="price__and__buy_inline">
            {additionalPrice}
            {price}
            <div
              onMouseOver={onHover}
              onFocus={onHover}
              onMouseOut={onMouseLeave}
              onBlur={onMouseLeave}
            >
              {buyBtn}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default memo(PureCard);
