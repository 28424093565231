import { useEffect, useCallback } from 'react';
import { useQuery } from '@shared/lib/routing';
import { useGetCitiesQuery } from '@entities/cities';
import { useTranslation } from 'react-i18next';
import { useConfirmCityModal } from './handler';
import type { ConfirmCityModalArgs } from './types';

export function ConfirmCity({
  showSelectCity,
  countryId,
  countryName,
  cityName,
  cityTextId,
}: ConfirmCityModalArgs & {
  showSelectCity: () => void;
  countryId: number;
}) {
  const { i18n } = useTranslation();
  const { data: citiesData, isSuccess } = useGetCitiesQuery({
    lang: i18n.language,
    country_id: countryId,
  });
  const { show } = useConfirmCityModal();
  const query = useQuery();
  const isFromRootPageWithDefaultCity = query.get(
    'fromRootPageWithDefaultCity'
  );
  const isSingleCity = isSuccess && citiesData.data.city.length === 1;

  const showConfirmModal = useCallback(() => {
    show({ countryName, cityName, cityTextId }).then(showSelectCity);
  }, [cityName, cityTextId, countryName, show, showSelectCity]);

  useEffect(() => {
    if (isSingleCity) return;
    if (isFromRootPageWithDefaultCity) {
      showConfirmModal();
    }
  }, [isFromRootPageWithDefaultCity, isSingleCity, showConfirmModal]);

  return null;
}
