import Emitter from 'frontend/Components/NewCartOrder/Emitters';
import { useEffect } from 'react';
import { useSelectAddressModal } from './select-address-modal-handler';

const sessionStorageKey = 'address-asked';

export const useSelectAddressFirst = (needToShowInCity: boolean) => {
  const modal = useSelectAddressModal();

  useEffect(() => {
    const onInCartClick = () => {
      const isShown = sessionStorage.getItem(sessionStorageKey) === 'true';
      if (isShown || !needToShowInCity) return;
      sessionStorage.setItem(sessionStorageKey, 'true');
      modal.show();
    };

    Emitter.addListener('CART_CHANGE_QUANTITY', onInCartClick);
    return () => {
      Emitter.removeListener('CART_CHANGE_QUANTITY', onInCartClick);
    };
  }, [modal, needToShowInCity]);
};
