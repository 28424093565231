import { LegacyRef } from 'react';
import RMCarousel, { CarouselProps } from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

interface ICarousel extends CarouselProps {
  carouselRef?: LegacyRef<RMCarousel>;
}
export function Carousel({ children, carouselRef, ...props }: ICarousel) {
  return (
    <RMCarousel ref={carouselRef} {...props}>
      {children}
    </RMCarousel>
  );
}
