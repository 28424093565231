import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InternalImage } from '@shared/ui/image';

interface IDynamicImageByLang {
  width: number;
  height: number;
  alt: string;
  className: string;
}
function DynamicImageByLang(props: IDynamicImageByLang) {
  const {
    i18n: { language },
  } = useTranslation();

  const [image, setImage] = useState<string>();

  useEffect(() => {
    import(`./assets/download-app-${language}.png`)
      .then((response) => {
        setImage(response.default);
      })
      .catch(() => {
        import(`./assets/download-app-en.png`).then((response) => {
          setImage(response.default);
        });
      });
  }, [language]);
  if (!image) {
    return null;
  }
  return <InternalImage {...props} src={image} />;
}

export { DynamicImageByLang };
