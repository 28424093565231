import { useCheckCountries } from '@entities/countries';
import { clsx } from 'clsx';

function NoImgRadioButton({ item, selected, onChange }) {
  const { isUkraine } = useCheckCountries();
  return (
    <li className="card-wok__option no-img">
      <input
        id={item.id}
        name={item.type}
        type="radio"
        value={item.id}
        checked={selected}
        onChange={onChange}
        className={clsx('sw-radio', 'card-wok__radio', { ua: isUkraine })}
      />
      <label htmlFor={item.id} className="card-wok__label">
        {item.title}
      </label>
    </li>
  );
}

export default NoImgRadioButton;
