import './CartAdditionals.scss';
import * as session from 'modules/session';
import { useCurrency } from '@entities/countries';
import Card from './Card/Card';

function CartAdditionals({ additional = [] }) {
  const { items } = session.get('cart') || {};
  const currency = useCurrency();
  return (
    <section className="cart-additional">
      {additional.map((product) => {
        if (!Object.keys(product).length) return null;
        const cartItem =
          items.find(({ product_id }) => +product_id === +product.id) || {};
        cartItem.product = product;
        return (
          <Card
            key={`additional-${product.id}`}
            position="cartAdditional"
            {...{ cartItem, currency }}
          />
        );
      })}
    </section>
  );
}

export default CartAdditionals;
