import './Vacancy.scss';
import { Component } from 'react';
import autobind from 'autobind-decorator';
import globalStore from 'modules/global-store';
import * as session from 'modules/session';
import { modalEmitter } from 'modules/event-emitters/index';
import ssr from 'modules/decorators/ssr';
import api from 'modules/helpers/api';
import { Button } from '@shared/ui/button';
import { ExternalImage } from '@shared/ui/image';
import { connect } from 'react-redux';
import { Breadcrumbs } from '@shared/ui/breadcrumbs';
import { PageContainer } from '@shared/ui/content-containers';
import { PageTitle } from '@shared/ui/titles';
import { Banner } from '@widgets/seoPage';
import VacanciesModal from '../../Components/Vacancies/VacanciesModal/VacanciesModal';
import ErrorComponent from '../../Components/ErrorComponent/ErrorComponent';
import CustomHelmet from '../../Components/CustomHelmet';

class VacancyPage extends Component {
  static LOADED_KEY = 'vacancy_loaded';

  static createDescriptionMarkup(description) {
    return { __html: description };
  }

  static renderDescription(description) {
    return (
      <div
        className="vacancy-item__description"
        dangerouslySetInnerHTML={VacancyPage.createDescriptionMarkup(
          description
        )}
      />
    );
  }

  static initialData = (fetch, params = {}, globalStore) => {
    const { vacancies_item: text_id } = params;
    return fetch('vacancies.get', { text_id })
      .then((result) => globalStore.set('vacancies_item', result))
      .catch((e) => {
        console.error('VacancyPage', e);
        return [];
      });
  };

  constructor(props) {
    super(props);
    this.state = {
      vacancies_item: globalStore.get('vacancies_item'),
    };
  }

  componentDidMount() {
    if (globalStore.get(VacancyPage.LOADED_KEY)) {
      globalStore.unlink(VacancyPage.LOADED_KEY);
    } else {
      VacancyPage.initialData(api, this.props.match.params, globalStore).then(
        () => {
          this.setState({
            vacancies_item: globalStore.get('vacancies_item', {}),
          });
        }
      );
    }
    document.body.style.overflow = '';
  }

  @autobind
  showVacanciesFormModal(event) {
    event.stopPropagation();

    const {
      vacancies_item: { id },
    } = this.state;

    modalEmitter.emit('Vacancies.Modal.Open', id);
  }

  render() {
    const { vacancies_item } = this.state;
    const {
      t,
      mainDomain,
      match: {
        params: { vacancies_item: text_id },
      },
    } = this.props;

    if (!vacancies_item) {
      return <div />;
    }

    if (vacancies_item && vacancies_item.length === 0) {
      return (
        <PageContainer>
          <ErrorComponent
            action_link="/vacancy"
            action_text={t('Vacancies.vacancy_get_all')}
          />
        </PageContainer>
      );
    }

    let { description } = vacancies_item;
    const { title = '', image } = vacancies_item;
    description = description.replace('<hr>', '');

    return (
      <>
        <Banner pathname={`/vakansii/${text_id}`} />
        <CustomHelmet
          title={title}
          image={`${session.get('protocol')}://${session.get(
            'location'
          )}/img/${image}`}
          ogTitle={title}
        />
        <PageContainer>
          <Breadcrumbs
            links={[
              {
                href: '/vakansii',
                text: t('Vacancy.vacancies_title'),
              },
              {
                text: title,
              },
            ]}
          />
          <PageTitle>{title}</PageTitle>
          <div className="vacancy-item">
            <ExternalImage
              className="vacancy-item__image"
              mainDomain={mainDomain}
              src={image}
            />
            {VacancyPage.renderDescription(description)}
          </div>
          <Button
            className="page-vacancy-button"
            onClick={this.showVacanciesFormModal}
          >
            {t('localization.Send resume')}
          </Button>
          <VacanciesModal />
        </PageContainer>
      </>
    );
  }
}

const mapStateToProps = ({ countries }) => ({
  mainDomain: countries.country.main_domain,
});

export default connect(mapStateToProps)(ssr(VacancyPage));
