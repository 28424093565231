import { ExternalImage } from '@shared/ui/image';
import { SmartLink } from '@shared/ui';
import styles from './styles.module.scss';
import CloseBannerBtn from './assets/closeBannerBtn.svg';
import { useBannerData } from '../../model/useBannerData';
import type { IBanner } from '../../model/types';

export function PageBanner({
  bannerData,
  mainDomain,
}: {
  bannerData: IBanner;
  mainDomain: string;
}) {
  const { visible, image, link, closeBanner } = useBannerData({ bannerData });

  if (!visible) {
    return null;
  }
  return (
    <div className={styles.banner}>
      <SmartLink href={link}>
        <ExternalImage
          mainDomain={mainDomain}
          alt="promo banner"
          src={`/${image}`}
          width={430}
          height={110}
        />
      </SmartLink>
      <button
        type="button"
        className={styles.closeButton}
        onClick={closeBanner}
      >
        <CloseBannerBtn />
      </button>
    </div>
  );
}
