import { modalEmitter } from 'modules/event-emitters';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { formaterTimeWithDate } from '@shared/lib/date';
import { productIds, currencySymbols } from '@shared/config';
import { IUserOrder, OrderStatus } from '../../model/types';
import styles from './orderListItem.module.scss';

export function OrderListItem({ order }: { order: IUserOrder }) {
  const { t } = useTranslation();
  const {
    items,
    hash,
    status_id,
    total,
    total_dlv,
    created_at,
    is_pickup,
    currency,
  } = order;

  const getOrderStatus = () => {
    switch (status_id) {
      case OrderStatus.Accepted:
        return t('Order.accepted');
      case OrderStatus.PreparingStart:
      case OrderStatus.PreparingEnd:
        return t('Order.preparing');
      case OrderStatus.Delivering:
        return is_pickup ? t('OrderInfo.pickup_done_2') : t('Order.delivering');
      case OrderStatus.Delivered:
        return is_pickup ? t('OrderInfo.pickup_done_3') : t('Order.delivered');
      case OrderStatus.Canceled:
        return t('Order.canceled');
      default:
    }
  };

  const getTotalSum = () => {
    if (!total) return `-`;

    return `${total + total_dlv}${'\u00A0'}${currencySymbols[currency]}`;
  };

  const openModal = () => {
    modalEmitter.emit('Reorder.Modal.Show', order);
  };

  const quantityItems = items.reduce((s, item) => {
    if (
      !item.parent_id &&
      item.price !== 0 &&
      item.product_id !== productIds.sticks
    ) {
      return s + item.qty;
    }
    return s;
  }, 0);

  return (
    <li className={styles.root}>
      <button
        className={clsx(styles.button, styles.container)}
        type="button"
        onClick={openModal}
      >
        <span className={clsx(styles.hash, styles.blackText, styles.item1)}>
          № {hash}
        </span>
        <div
          className={clsx(
            { [styles.red]: status_id !== OrderStatus.Canceled },
            styles.item2
          )}
        >
          {getOrderStatus()}
        </div>
        <div className={styles.item3}>
          {t('Order.positions')}:
          <span className={styles.blackText}>{` ${quantityItems}`}</span>
        </div>
        <div className={styles.item4}>
          <span>{t('Order.sum')}: </span>
          <span className={styles.blackText}>{getTotalSum()}</span>
        </div>
        <span className={styles.item5}>{formaterTimeWithDate(created_at)}</span>
      </button>
    </li>
  );
}
