import { useState } from 'react';
import api from 'modules/helpers/api';
import * as session from 'modules/session';
import { useTranslation } from 'react-i18next';
import { Button } from '@shared/ui/button';
import Card from 'src/frontend/Components/NewCartOrder/Modals/Components/card/Card';
import { getItemsFromCart } from 'src/frontend/Components/NewCartOrder/Helpers/index';
import Emitter from 'src/frontend/Components/NewCartOrder/Emitters';
import {
  getActions,
  getUserGifts,
} from 'src/frontend/Components/NewCartOrder/Modals/helpers';
// eslint-disable-next-line css-modules/no-unused-class
import modalStyles from 'src/frontend/Components/NewCartOrder/Modals/main-card-modal/mainCartModal.module.scss';
import { IdsType, useGetProducts } from '@entities/product';
import { ModalParagraph } from '@shared/ui/modal-paragraph';
import styles from './disabledProductsModal.module.scss';

export interface IDisabledValue {
  product_ids: IdsType;
  isDelivery: boolean;
  handleAfterModal: (success: boolean) => void;
}

interface IDisabledProductsModal {
  value: IDisabledValue;
}

function DisabledProductsModal({
  value: { product_ids, isDelivery, handleAfterModal },
}: IDisabledProductsModal) {
  const { t } = useTranslation();
  const [buttonsDisabled, setButtonsDisabled] = useState(false);
  const products = useGetProducts(product_ids);

  const disableActions = () => {
    const { cart } = session.get();
    const actionsIds = getActions(product_ids, cart);
    const { giftSettings } = cart;

    if (actionsIds.length) {
      actionsIds.forEach((id) => {
        if (giftSettings[id]) {
          giftSettings[id].disable = true;
        }
      });

      return api('cart.gifts', { giftSettings }).then((cartContent) => {
        session.set('cart', cartContent);
        Emitter.emit('CART_CHANGE');
      });
    }
    return Promise.resolve({});
  };

  const findRemoveProductIds = () => {
    const items = getItemsFromCart();
    const arr: number[] = [];
    product_ids.forEach((product_id) => {
      items.forEach((item: any) => {
        if (item.child) {
          const found = item.child.find(
            (child: any) => +child.product_id === +product_id
          );
          if (found) {
            arr.push(item.product_id);
            return false;
          }
        }
        if (+item.product_id === +product_id) {
          arr.push(item.product_id);
        }
      });
    });
    return arr;
  };

  const disableUserGifts = () => {
    // TODO rewrite to async/await
    return new Promise((resolve, reject) => {
      const userGifts = session.get('userGifts');
      const ids = getUserGifts(product_ids, userGifts);
      if (ids.length) {
        session.set('userGifts', ids);
        // eslint-disable-next-line no-promise-executor-return
        return api('cart.remove.user.gifts', { ids })
          .then((cart) => {
            session.set('cart', cart);
            Emitter.emit('CART_CHANGE');
            Emitter.emit('USER_GIFTS_CHANGE');
            resolve({});
          })
          .catch((e) => {
            console.log(e);
            reject();
          });
      }
      resolve({});
    });
  };

  const deleteProducts = () => {
    return new Promise((resolve, reject) => {
      const ids = findRemoveProductIds();
      disableActions()
        .then(() => {
          api('cart.remove', { ids })
            .then((cart) => {
              session.set('cart', cart);
              Emitter.emit('CART_CHANGE');
              Emitter.emit('MODAL_CLOSE');
              disableUserGifts().finally(() => resolve(ids));
            })
            .catch((response) => {
              console.error(response);
              Emitter.emit('CART_CHANGE');
              disableUserGifts().finally(() => reject());
            });
        })
        .catch((e) => {
          console.log(e);
          disableUserGifts().finally(() => reject());
        });
    });
  };

  const handleDelete = () => {
    setButtonsDisabled(true);
    deleteProducts()
      .then(() => {
        handleAfterModal(true);
      })
      .catch(() => setButtonsDisabled(false));
  };

  const routeToCart = () => {
    Emitter.emit('MODAL_CLOSE');
    handleAfterModal(false);
    // cancel();
  };

  if (!products.length) return null;

  const questionText = isDelivery
    ? t('ConfirmDisabledDelition.questionPickup')
    : t('ConfirmDisabledDelition.question_delivery');

  return (
    <div className={styles.disabledListsModal}>
      <ModalParagraph>{questionText}</ModalParagraph>
      <div className={styles.listStyle}>
        {!!products.length && <Card products={products} />}
      </div>
      <div className={modalStyles.btnsContainer}>
        <Button
          onClick={handleDelete}
          className={styles.actionButton}
          disabled={buttonsDisabled}
        >
          {t('ConfirmDisabledDelition.yes')}
        </Button>
        <Button
          onClick={routeToCart}
          className={styles.actionButton}
          disabled={buttonsDisabled}
        >
          {t('ConfirmDisabledDelition.to_cart')}
        </Button>
      </div>
    </div>
  );
}

export default DisabledProductsModal;
