import { useEffect } from 'react';
import { clsx } from 'clsx';
import { useLocation } from 'react-router-dom';
import { analytics } from 'frontend/analytics/analytics';
import {
  getEcomDataToAnalytics,
  getFbPixelDataToCart,
} from 'frontend/analytics/analyticsHelper';
import { withBreakpoints } from 'frontend/hocs';

import { useSelectCountryCurrency } from '@entities/countries';
import Additional from './Additional';
import Emitter from '../../NewCartOrder/Emitters';
import style from './CardModal.module.scss';
import CardComponent from './CardComponent';

const useAnalitics = (product) => {
  const countryCurrency = useSelectCountryCurrency();
  useEffect(() => {
    analytics.ecom(
      'detail',
      getEcomDataToAnalytics({ product, qty: 1 }),
      countryCurrency
    );
    analytics.fbPixel(
      'ViewContent',
      getFbPixelDataToCart(product, countryCurrency)
    );
  }, [product, countryCurrency]);
};
function CardModal({ value, isMobile }) {
  const { product, id } = value;
  const location = useLocation();
  useAnalitics(product);

  useEffect(() => {
    const changeHistory = (textId) => {
      if (window.history.pushState) {
        // Слэш в конце ссылки убран специально для того, чтоб браузер
        // не сохранял позицию скролла при обновлении страницы с открытой модалкой
        window.history.pushState({}, null, `${location.pathname}${textId}`);
      }
    };

    const removeHistory = () => {
      if (window.history.replaceState) {
        window.history.replaceState({}, null, location.pathname);
      }
    };

    if (product && product.text_id) {
      changeHistory(product.text_id);
    }

    Emitter.addListener('MODAL_CLOSE', removeHistory);

    return () => {
      Emitter.removeListener('MODAL_CLOSE', removeHistory);
      removeHistory();
    };
  }, [location, product]);

  const modalCard = clsx(style.modalCard, {
    [style.noAdditional]: isMobile,
  });

  return (
    <div className={modalCard}>
      {product && (
        <CardComponent product={product} cartId={id} position="modal" />
      )}
      {!isMobile && product && <Additional product={product} />}
    </div>
  );
}

export default withBreakpoints(CardModal);
