import { baseApi } from '@shared/api';
import type { ISeoPage } from './types';

const seoPageApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    seoPage: build.query<
      { data: ISeoPage },
      { city: string; lang: string; url: string }
    >({
      query: (params) => {
        return {
          url: `/endpoint/seo/page`,
          params,
        };
      },
    }),
  }),
});

export const { useSeoPageQuery } = seoPageApi;
