import { useTranslation } from 'react-i18next';
import styles from './vacanciesInfo.module.scss';

export function VacanciesInfo() {
  const { t } = useTranslation();

  const vacanciesInfoScheme = [
    {
      id: 1,
      title: t('VacanciesInfo.Development'),
      description: t('VacanciesInfo.DevelopmentDescription'),
    },
    {
      id: 2,
      title: t('VacanciesInfo.Training'),
      description: t('VacanciesInfo.TrainingDescription'),
    },
    {
      id: 3,
      title: t('VacanciesInfo.Capabilities'),
      description: t('VacanciesInfo.CapabilitiesDescription'),
    },
  ];

  return (
    <div>
      <h3 className={styles.titleWrapper}>
        <span className={styles.title}>
          {t('VacanciesInfo.AdvantagesOfWorking')}
        </span>
      </h3>

      <ul className={styles.advantages}>
        {vacanciesInfoScheme.map(({ id, title, description }) => {
          return (
            <li key={id}>
              <div className={styles.subtitle}>{title}</div>
              <div className={styles.text}>{description}</div>
            </li>
          );
        })}
      </ul>
    </div>
  );
}
