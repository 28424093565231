import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from 'modules/helpers/api';
import { afterCartItem } from 'modules/helpers/cart-helper';
import { t } from 'i18next'; // удалить, испльзовать через хук
import type { ICart, ICartItem } from 'types/cartTypes';
import { AppThunkDispatch } from '@shared/lib/store';
import * as session from 'modules/session';
import {
  IComplectationFree,
  IComplectationItem,
  IComplectationPaid,
  IComplectationState,
} from '../types';

const paidItemsSender = async (
  toSendItems: { id: number; qty: number }[],
  onError: (text: string) => void
) => {
  try {
    const newCart: ICart = await api('cart.items', toSendItems);
    await afterCartItem({ newCart, t, onError });
    return newCart;
  } catch (e) {
    throw Error(String(e));
  }
};

const getCartItems = () => {
  const { items = [] }: { items: ICartItem[] } = session.get('cart');
  return items;
};

const sortCartComplectation = ({
  items,
  compItems,
}: {
  items: ICartItem[];
  compItems: IComplectationItem[];
}) => {
  const paidItems = {} as IComplectationPaid;
  items.forEach(({ id, qty }) => {
    if (compItems.find(({ id: itemId }) => id === itemId))
      paidItems[id] = { qty };
  });
  return paidItems;
};

const initState: IComplectationState = {
  free: {},
  paid: {},
  availiable: [],
  isLoading: false,
};

export const addPaid = createAsyncThunk<
  IComplectationPaid,
  { toSendItems: IComplectationPaid; onError: (text: string) => void },
  { dispatch: AppThunkDispatch }
>('complectation/addPaid', async ({ toSendItems, onError }) => {
  const sendItems = Object.keys(toSendItems).map((key) => {
    return { id: Number(key), qty: toSendItems[key].qty };
  });
  const { items, complectations } = await paidItemsSender(sendItems, onError);
  if (!complectations || !complectations.length) {
    return {};
  }
  const paid = sortCartComplectation({
    items,
    compItems: complectations,
  });
  return paid;
});

export const deleteAllPaid = createAsyncThunk<
  IComplectationPaid,
  (text: string) => void,
  { dispatch: AppThunkDispatch }
>('complectation/deleteAllPaid', async (onError, thunkApi) => {
  const {
    complectation: { paid: currentPaid },
  } = thunkApi.getState() as RootState;
  const deleteItems = Object.keys(currentPaid).map((key) => {
    return { id: Number(key), qty: -currentPaid[key].qty };
  });
  if (!deleteItems.length) return {};
  const { items, complectations } = await paidItemsSender(deleteItems, onError);
  if (!complectations || !complectations.length) {
    return {};
  }
  const paid = sortCartComplectation({
    items,
    compItems: complectations,
  });
  return paid;
});

export const complectationSlice = createSlice({
  name: 'complectation',
  initialState: initState,
  reducers: {
    addFree: (state, action: PayloadAction<{ id: number; qty: number }>) => {
      const { free } = state;
      const { id, qty } = action.payload;
      const newFree = { ...free };
      if (free[id]) {
        newFree[id] = { qty: free[id].qty + qty, max: free[id].max };
      } else {
        const max =
          state.availiable?.find(({ id: itemId }) => id === itemId)?.max || 0;
        newFree[id] = { qty: max + qty, max };
      }
      state.free = newFree;
    },
    removeFreeItems: (state, action: PayloadAction<number[]>) => {
      const { free, availiable } = state;
      availiable.forEach(({ id, max }) => {
        if (action.payload.includes(id)) {
          free[id] = { qty: 0, max };
        }
      });
      state.free = free;
    },
    setPaid: (state, action: PayloadAction<IComplectationPaid>) => {
      state.paid = action.payload;
    },
    setAvailable: (state, action: PayloadAction<IComplectationItem[]>) => {
      const available = action.payload;
      if (!available || !available.length) {
        state.free = {};
        state.paid = {};
        state.availiable = [];
        state.isLoading = false;
        return;
      }
      const { free, availiable: oldAvailable } = state;

      const newFree: IComplectationFree = {};
      available?.forEach(({ id, max }) => {
        if (free[id]) {
          const currentQty = free[id].qty;
          const oldAvailableQty = oldAvailable.find(
            ({ id: aId }) => id === aId
          )?.max;
          newFree[id] = {
            qty: oldAvailableQty !== max ? max : currentQty,
            max,
          };
        }
      });
      const sortedAvaliableComplectation = [...available].sort(
        (a, b) => a.id - b.id
      );
      state.free = newFree;
      state.availiable = sortedAvaliableComplectation;
      state.paid = sortCartComplectation({
        items: getCartItems(),
        compItems: sortedAvaliableComplectation,
      });
    },
    resetComplectation: (state) => {
      state.free = {};
      state.paid = {};
      state.availiable = [];
      state.isLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addPaid.fulfilled, (state, action) => {
      state.paid = action.payload;
      state.isLoading = false;
    });
    builder.addCase(addPaid.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(addPaid.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(deleteAllPaid.fulfilled, (state, action) => {
      state.paid = action.payload;
      state.isLoading = false;
    });
    builder.addCase(deleteAllPaid.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteAllPaid.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

const selectComplectationState = (state: RootState) => {
  return state.complectation;
};

const selectFree = (state: RootState) => {
  return state.complectation.free;
};

const selectPaid = (state: RootState) => {
  return state.complectation.paid;
};

const selectIsLoading = (state: RootState) => {
  return state.complectation.isLoading;
};

const selectAvailable = (state: RootState) => {
  return state.complectation.availiable;
};

export const complectationSliceSelectors = {
  selectComplectationState,
  selectFree,
  selectPaid,
  selectAvailable,
  selectIsLoading,
};

export const { addFree, removeFreeItems, setAvailable, resetComplectation } =
  complectationSlice.actions;
