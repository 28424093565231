import { useModal } from '@ebay/nice-modal-react';
import { modalIds } from '@features/modals/modal-ids';
import type { IErrorCartModal } from '../ui/ErrorCartModal';

export const useErrorCartModal = () => {
  const modal = useModal(modalIds.cartErrorModalId);

  return {
    ...modal,
    show: (args: IErrorCartModal) => modal.show(args),
  };
};

export const usePickupErrorUAModal = () => {
  const modal = useModal(modalIds.uaPickupErrorModalId);

  return {
    ...modal,
    show: () => modal.show(),
  };
};
