import { useEffect, useState } from 'react';
import { useAppSelector } from '@shared/lib/store';
import { isThereFree } from '@entities/complectation/lib';
import { complectationSliceSelectors } from '@entities/complectation/model/slice';

const complectationConfirm = 'complectationConfirm';

export const useComplectationConfirm = () => {
  const availiable = useAppSelector(
    complectationSliceSelectors.selectAvailable
  );

  const paid = useAppSelector(complectationSliceSelectors.selectPaid);

  const [isAsked, setIsAsked] = useState(false);

  const setConfirmed = () => {
    sessionStorage.setItem(complectationConfirm, 'true');
    setIsAsked(true);
  };

  useEffect(() => {
    if (sessionStorage.getItem(complectationConfirm)) {
      setIsAsked(true);
    }
  }, []);

  return {
    isConfirmed:
      !availiable.length ||
      isThereFree(availiable) ||
      !!Object.keys(paid).length ||
      isAsked,
    setConfirmed,
  };
};
