import { useTranslation } from 'react-i18next';
import styles from './items.module.scss';
import SwLogoImage from './assets/sw-logo.png';
import OpenYourStoreImage from './assets/open-your-store.png';
import SwHereImage from './assets/sw-here-image.png';

export const useBlocksScheme = () => {
  const { t } = useTranslation();

  const items = [
    {
      name: 'about',
      imageSrc: SwLogoImage,
      imageAlt: t('Logo.sushiwok_logo'),
      title: t('Main.federal_network'),
      description: 'take-away',
      listItems: [
        t('Main.more_than_shops'),
        t('Main.more_than_cities'),
        t('Main.more_than_customers'),
      ],
      className: '',
    },
    {
      name: 'franchise',
      imageSrc: OpenYourStoreImage,
      imageAlt: t('Main.open_your_store'),
      title: t('Main.open_yours'),
      description: t('localization.companyName'),
      listItems: [
        t('Main.franchise_first'),
        t('Main.franchise_profit'),
        t('Main.franchise_quantity'),
      ],
      className: styles.mainPartRight,
    },
    {
      name: 'takeaway',
      imageSrc: SwHereImage,
      imageAlt: t('Main.sushiwok_here'),
      title: t('Main.in any situation'),
      description: 'take-away',
      listItems: [
        t('Main.alwaysNear'),
        t('Main.for_any_company'),
        t('Main.profitable_and_tasty'),
      ],
      className: styles.mainPartLast,
    },
  ];

  const findbyName = (name: string) => items.find((item) => item.name === name);

  return { findbyName };
};
