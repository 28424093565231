import { ExternalImage } from '@shared/ui/image';
import { useCurrency, useSelectCountryMainDomain } from '@entities/countries';
import { AvailableInfo } from '@entities/product';
import styles from './card.module.scss';

function Card({ products, title = '' }) {
  const currency = useCurrency();
  const mainDomain = useSelectCountryMainDomain();
  return (
    <div>
      {title && <div className={styles.rowTitleContainer}>{title}</div>}
      {products.map((product, index) => {
        const {
          images,
          title,
          discount_price,
          price,
          available,
          available_from,
          available_to,
        } = product;

        const isDiscountPrice = !!discount_price;

        return (
          <div className={styles.productRow} key={index}>
            <ExternalImage
              mainDomain={mainDomain}
              className={`${styles.imgContainer}/80x80`}
              src={images[0]?.filename}
              alt={title}
              width={80}
              height={80}
            />
            <div className={styles.wrapperContainer}>
              <div className={styles.titleContainer}>
                <p>{title}</p>
                <AvailableInfo
                  available={available}
                  available_from={available_from}
                  available_to={available_to}
                />
              </div>
              {isDiscountPrice ? (
                <div className={styles.priceContainer}>
                  <p>
                    <strong>
                      {price}&nbsp;{currency}
                    </strong>
                    <span>
                      {discount_price}&nbsp;{currency}
                    </span>
                  </p>
                </div>
              ) : undefined}
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default Card;
