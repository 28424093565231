import { baseApi } from '@shared/api';
import { IProduct } from 'types/product';

const getProductApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getProduct: build.query<
      { data: { product: IProduct[] } },
      { lang: string; city: string; id: number[] }
    >({
      query: (params) => ({
        url: '/endpoint/product/index',
        method: 'GET',
        params,
      }),
    }),
  }),
});

export const { useGetProductQuery } = getProductApi;
