import { clsx } from 'clsx';
import styles from './sortingDirection.module.scss';

interface ISortingDirection {
  direction: 'up' | 'down';
}

function SortingDirection({ direction }: ISortingDirection) {
  const triangleStyle = clsx(styles.triangle, {
    [styles.triangleUp]: direction === 'up',
  });
  return <div className={triangleStyle} />;
}

export default SortingDirection;
