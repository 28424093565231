import { useEffect } from 'react';
import * as session from 'modules/session';
import productHelper from 'modules/helpers/product-helper';
import api from 'modules/helpers/api';
import { afterCartItem, getProductIds } from 'modules/helpers/cart-helper';
import { analytics } from 'frontend/analytics/analytics';
import {
  getEcomDataToAnalytics,
  getFbPixelDataToCart,
} from 'frontend/analytics/analyticsHelper';
import Emitter from 'frontend/Components/NewCartOrder/Emitters';
import { useCurrency } from '@entities/countries';
import { useTranslation } from 'react-i18next';
import { useErrorCartModal } from '@features/modals/cart-error-modal';

export const useCartQtyHandler = () => {
  const countryCurrency = useCurrency();
  const { t } = useTranslation();
  const errorCartModal = useErrorCartModal();

  const onError = (text) => {
    errorCartModal.show(text);
  };

  useEffect(() => {
    const cartQuantityHandler = ({ id, qty, child }) => {
      if (qty > 0) {
        const cart = session.get('cart');
        const products = getProductIds(cart);
        if (!products.includes(id)) {
          analytics.productCartDesire();
        }
      }
      api('cart.item', { id, qty, child }).then((cart = {}) => {
        afterCartItem({ newCart: cart, t, onError, item: { id, qty, child } });
      });

      const actionTypeToAnalytics = qty > 0 ? 'add' : 'remove';
      analytics.ecom(
        actionTypeToAnalytics,
        getEcomDataToAnalytics({
          product: productHelper.getCartProduct(id),
          qty,
        }),
        countryCurrency
      );
      analytics.fbPixel(
        'AddToCart',
        getFbPixelDataToCart(productHelper.getCartProduct(id), countryCurrency)
      );
    };

    Emitter.addListener('CART_CHANGE_QUANTITY', cartQuantityHandler);
    return () => {
      Emitter.removeListener('CART_CHANGE_QUANTITY', cartQuantityHandler);
    };
  }, [countryCurrency, t]);
};
