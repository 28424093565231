import courierImage from '../ui/assets/courier.png';
import cashierImage from '../ui/assets/cashier.png';

export const config = [
  {
    title: 'О работе Продавца-кассира',
    videoLink:
      '//www.youtube.com/embed?v=F_zSiITCLoA&list=PLcRZ4YQMIWWu0W_fgqlYtj3AdtD4qrr5K',
    previewImageUrl: cashierImage,
  },
  {
    title: 'О вакансии Курьер',
    videoLink: '//www.youtube.com/embed/AVneBkYZ2aw',
    previewImageUrl: courierImage,
  },
];
