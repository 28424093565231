import { Helmet } from 'react-helmet-async';
import * as session from 'modules/session';
import { PageContainer } from '@shared/ui/content-containers';
import { Banner } from '@widgets/seoPage';

const getInformation = () => {
  const isWindowExist = typeof window !== 'undefined';
  if (!isWindowExist) return null;
  const { order: { organizationContactInformation } = {} } =
    session.get('cart');
  return organizationContactInformation;
};

function OrganizationContactInformation() {
  const organizationContactInformation = getInformation();

  return (
    <>
      <Banner pathname="/dannye-prodavca" />
      <Helmet title="Юридическое лицо" />
      <PageContainer>
        {organizationContactInformation && (
          <div
            dangerouslySetInnerHTML={{ __html: organizationContactInformation }}
          />
        )}
      </PageContainer>
    </>
  );
}

export default OrganizationContactInformation;
