import { useAppSelector } from '@shared/lib/store';

const selectStoreId = (state: RootState) => {
  return state.pickupStore.selectedStoreId;
};

const selectStoreById = <T extends { id: number }>(
  state: RootState,
  stores: T[]
) => {
  const storeId = state.pickupStore.selectedStoreId;
  const currentStore = stores.find((store) => store.id === storeId);
  return currentStore || null;
};

export const useSelectedStoreId = () => useAppSelector(selectStoreId);

export const useSelectedStore = <T extends { id: number }>(stores: T[]) =>
  useAppSelector((state) => selectStoreById(state, stores));
