import escapeRegexp from 'modules/helpers/escape-regexp';

function stripCity(url, city) {
  if (!city) {
    return url;
  }
  const re = new RegExp(`^/?${escapeRegexp(city)}`);
  return url.replace(re, '');
}

export { stripCity };
