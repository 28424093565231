import { Link, useLocation } from 'react-router-dom';
import { clsx } from 'clsx';
import { isAbsoluteUrl } from '@shared/lib';
import { useCityTextIdParam } from '@shared/lib/routing';

export interface ISmartLink
  extends Omit<
    React.DetailedHTMLProps<
      React.AnchorHTMLAttributes<HTMLAnchorElement>,
      HTMLAnchorElement
    >,
    'ref' // for Link
  > {
  activeClassName?: string;
  className?: string;
  href?: string;
}
export function SmartLink({
  activeClassName,
  className,
  children,
  href,
  ...props
}: ISmartLink) {
  const { pathname } = useLocation();
  const cityTextId = useCityTextIdParam();
  if (!href) {
    return (
      <a href={href} className={className} {...props}>
        {children}
      </a>
    );
  }
  const cleanHref = href.replace(/\\/g, '');
  const hrefWithCity = `/${cityTextId}/${cleanHref}`
    .replace(/\/{2,}/g, '/')
    .replace(/\/?$/, '/');
  return isAbsoluteUrl(cleanHref) ? (
    <a className={className} href={cleanHref} target="_blank" rel="noreferrer">
      {children}
    </a>
  ) : (
    <Link
      to={hrefWithCity}
      className={clsx(className, {
        [activeClassName as string]: pathname === hrefWithCity,
      })}
      {...props}
    >
      {children}
    </Link>
  );
}
