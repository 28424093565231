import queryString, { StringifiableRecord } from 'query-string';

export const pushQuery = (query: StringifiableRecord) => {
  const url = queryString.stringifyUrl({
    url: window.location.pathname,
    query,
  });

  window.history.pushState(null, '', url);
};
