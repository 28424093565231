import { useAppSelector } from '@shared/lib/store';
import { SmartLink } from '@shared/ui';
import styles from './footer-links.module.scss';
import { footerLinksSliceSelectors } from '../model/slice';

export function FooterLinks() {
  const links = useAppSelector(footerLinksSliceSelectors.selectFooterLinks);

  return (
    <div className={styles.navigationContainer}>
      {links.map((link) => {
        const { title, url, id } = link;

        return (
          <SmartLink key={id} href={url} className={styles.link}>
            {title}
          </SmartLink>
        );
      })}
    </div>
  );
}
