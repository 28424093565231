import { useTranslation } from 'react-i18next';
import useUserBonuses from 'frontend/data/user/user-bonuses/use-user-bonuses';
import { formaterDate } from '@shared/lib/date';
import { Subtitle } from '@shared/ui/titles';
import { BlockContainer } from '@shared/ui/content-containers';
import { LinkButton } from '@shared/ui/button';
import { SmartLink } from '@shared/ui';
import styles from './userPromoCode.module.scss';

function UserPromoCode() {
  const { t } = useTranslation();
  const { data } = useUserBonuses();

  if (!data || !data.promo.length) {
    return null;
  }
  return (
    <BlockContainer>
      <Subtitle>{t('BlockProducts.user_promo_code')}</Subtitle>
      <ul className={styles.list}>
        {data.promo.map(({ promocode, name, expiry, action }) => (
          <li key={promocode} className={styles.element}>
            <span className={styles.text}>
              {name}!&nbsp;{t('UserPromoCode.validUntil')}&nbsp;
              {formaterDate(expiry, 'dd.MM.yyyy')}
            </span>
            <div className={styles.block}>
              <span className={styles.promo}>{promocode}</span>
              <LinkButton
                href={`/cart?userProfilePromo=${promocode}`}
                variant="contained"
                className={styles.btn}
              >
                {t('UserPromoCode.apply')}
              </LinkButton>
            </div>
            <SmartLink href={action} className={styles.info}>
              {t('UserPromoCode.info_stock')}
            </SmartLink>
          </li>
        ))}
      </ul>
    </BlockContainer>
  );
}

export default UserPromoCode;
