import globalStore from 'modules/global-store';
import { InternalImage } from '@shared/ui/image';
import { Media } from '@shared/lib/media';
import styles from './by-payment.module.scss';
import byPaymentSetImg from './assert/fullSet.png';
import byMobilePaymentSetImg from './assert/mobileFull.png';
import {
  byRequisites,
  requiredCities,
} from '../../config/by-requisites-scheme';

export function ByPayment() {
  const { text_id }: { text_id: string } = globalStore.get('current_city');
  const isRequiredCities = requiredCities.includes(text_id);
  if (!isRequiredCities) return null;
  return (
    <>
      <Media lessThan="laptop" className={styles.byPaymentSet}>
        <InternalImage
          src={byMobilePaymentSetImg}
          alt="Payment Set"
          width={360}
          height={640}
        />
      </Media>
      <Media greaterThanOrEqual="laptop" className={styles.byPaymentSet}>
        <InternalImage
          src={byPaymentSetImg}
          alt="Payment Set"
          width={1839}
          height={180}
        />
      </Media>
      <p className={styles.requisites}>
        {byRequisites[text_id as keyof typeof byRequisites]}
      </p>
    </>
  );
}
