import productHelper from 'modules/helpers/product-helper';
import { useCurrency } from '@entities/countries';

function Price({
  product,
  cart_item,
  deliveryValue,
  choisenDeliveryType,
  isWidget = false,
}) {
  const havePrices = !!product.prices;
  let priceObjct;
  if (havePrices) {
    const keys = Object.keys(product.prices);
    priceObjct = deliveryValue ? choisenDeliveryType : product.prices[keys[0]];
  }

  const productPrice = havePrices ? priceObjct.price : product.price;
  const productPriceOld = havePrices ? priceObjct.price_old : product.price_old;

  const priceCurrency = useCurrency();
  const {
    specialStyle,
    specialStyle: { price = {} } = {},
    decoration: { price_bg_color: priceBg = '', price_color = '' },
  } = product;
  let totalPrice = 0;
  if (cart_item && cart_item.child) {
    totalPrice = productPrice;
    cart_item.child.forEach((item) => {
      const topping = productHelper.getCartProduct(+item.product_id);
      if (topping) {
        totalPrice += +item.price * +item.qty;
      }
    });
  }

  if (product.is_action) {
    if (+productPriceOld === 0 || +productPrice >= +productPriceOld) {
      return (
        <div
          itemScope
          itemType="http://schema.org/Offer"
          className={`card__price--stock ${
            specialStyle === 'Price' ? 'card__price--special' : ''
          }`}
        >
          <meta content="RUB" itemProp="price" />
          <span
            className={`card__price__current--is-action ${
              priceBg ? 'price-special' : ''
            }`}
            style={
              !isWidget
                ? { ...price, backgroundColor: priceBg, color: price_color }
                : {}
            }
          >
            <span itemType="https://schema.org/priceCurrency" itemScope>
              {totalPrice > 0 ? totalPrice : productPrice}
            </span>
            &nbsp;
            {priceCurrency}
          </span>
        </div>
      );
    }

    return (
      <div
        itemScope
        itemType="http://schema.org/Offer"
        className={`card__price--stock ${
          specialStyle === 'Price' ? 'card__price--special' : ''
        }`}
      >
        <meta content="RUB" itemProp="price" />
        <span
          className={`card__price__current--is-action ${
            priceBg ? 'price-special' : ''
          }`}
          style={
            !isWidget
              ? { ...price, backgroundColor: priceBg, color: price_color }
              : {}
          }
        >
          <span itemType="https://schema.org/priceCurrency" itemScope>
            {totalPrice > 0 ? totalPrice : productPrice}
          </span>
          &nbsp;
          {priceCurrency}
        </span>
        {!priceBg ? (
          <span className="card__price__old">
            {productPriceOld}&nbsp;{priceCurrency}
          </span>
        ) : undefined}
      </div>
    );
  }
  return (
    <div
      itemScope
      itemType="http://schema.org/Offer"
      className={`card__price ${
        specialStyle === 'Price' ? 'card__price--special' : ''
      }`}
    >
      <meta content="RUB" itemProp="price" />
      <span
        className={`card__price__current ${priceBg ? 'price-special' : ''}`}
        style={
          !isWidget
            ? { ...price, backgroundColor: priceBg, color: price_color }
            : {}
        }
      >
        <span itemType="https://schema.org/priceCurrency" itemScope>
          {totalPrice > 0 ? totalPrice : productPrice}
        </span>
        &nbsp;
        {priceCurrency}
      </span>
    </div>
  );
}

export default Price;
