import { useTranslation } from 'react-i18next';
import { useAppSelector } from '@shared/lib/store';
import { useCurrency } from '@entities/countries';
import { deliverySliceSelectors } from '../../model/slice';
import styles from './freeDeliveryWarning.module.scss';

function FreeDeliveryWarning({
  orderMin,
  freeOrderCost,
  total = 0,
}: {
  orderMin: number;
  freeOrderCost: number;
  total?: number;
}) {
  const { t } = useTranslation();
  const currency = useCurrency();
  const { deliveryData } = useAppSelector(
    deliverySliceSelectors.selectDeliveryState
  );

  const freeSumm = deliveryData ? deliveryData.zero_min_order : freeOrderCost;

  if (!freeSumm) return null;

  const lacks = freeSumm - total;

  if (total < orderMin || lacks <= 0) return null;

  return (
    <span className={styles.warningText}>
      {t('BlockProducts.freeDeliveryWarning', {
        lacks: `${lacks}\u00A0${currency}`,
      })}
    </span>
  );
}

export default FreeDeliveryWarning;
