import { useEffect, useState } from 'react';

export const useIsBannerClosed = () => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (!localStorage.getItem('isClosedBanner')) {
      setVisible(true);
    }
  }, []);

  return {
    visible,
    closeBanner: () => {
      setVisible(false);
      localStorage.setItem('isClosedBanner', 'true');
    },
  };
};
