import { useEffect, useState } from 'react';
import './RecommendedProducts.scss';
import * as session from 'modules/session';
import { useTranslation } from 'react-i18next';
import api from 'modules/helpers/api';
import { Subtitle } from '@shared/ui/titles';
import { BlockContainer } from '@shared/ui/content-containers';
import ListRecommendation from './listRecomendations/ListRecomendation';

const getRecommendIds = () => {
  const { items } = session.get('cart', {});
  if (Array.isArray(items)) {
    return items.map(({ product_id }) => +product_id);
  }
  return [];
};

function RecommendedProducts() {
  const { t } = useTranslation();
  const [recommendations, setRecommendations] = useState([]);

  const isRecommendedProducts = recommendations && recommendations.length > 0;

  useEffect(() => {
    const ids = getRecommendIds();
    if (ids && ids.length) {
      api('cart.get_recommendations', { products: ids })
        .then((products) => {
          setRecommendations(products.filter(({ available }) => available));
        })
        .catch((e) => {
          console.error('NewCart 1', e);
          return [];
        });
    }
  }, []);
  return isRecommendedProducts ? (
    <BlockContainer>
      <Subtitle>{t('BlockProducts.recommend_to_add')}</Subtitle>
      <ListRecommendation recommendations={recommendations} />
    </BlockContainer>
  ) : null;
}

export default RecommendedProducts;
