import globalStore from 'modules/global-store';
import { ICity, IInfo } from './types';

export const useSelectCity = (): ICity => {
  return globalStore.get('current_city');
};

export const useSelectCityInfo = (): IInfo => {
  return globalStore.get('current_city').info;
};
