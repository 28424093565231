import { ExternalImage } from '@shared/ui/image';
import htmlParser from '@shared/lib/html-parser';
import { useSelectCountryMainDomain } from '@entities/countries';

interface iImageReplacer {
  mainDomain: string;
  src: string;
  alt?: string;
}
const imageReplacer = (props: iImageReplacer) => {
  return (
    <ExternalImage
      mainDomain={props.mainDomain}
      src={props.src.replace('/img', '')}
      alt={props.alt || 'action image'}
      width={740}
      height={490}
    />
  );
};

const parseDescription = ({
  description,
  mainDomain,
}: {
  description: string;
  mainDomain: string;
}) => {
  try {
    const parsed = htmlParser<iImageReplacer>(description, {
      from: 'img',
      to: (props) => imageReplacer({ ...props, mainDomain }),
    });
    return parsed;
  } catch (e) {
    console.error(e);
    return null;
  }
};

function Description({ description }: { description: string }) {
  const mainDomain = useSelectCountryMainDomain();
  return <>{parseDescription({ description, mainDomain })}</>;
}

export default Description;
