import { Component } from 'react';
import { withTranslation } from 'react-i18next';
import queryString from 'query-string';
import api from 'modules/helpers/api';
import { PageContainer } from '@shared/ui/content-containers';
import { PageTitle } from '@shared/ui/titles';
import globalStore from 'src/modules/global-store';
import ErrorPage from 'src/frontend/Pages/ErrorPage/ErrorPage';
import { stripCity } from 'src/modules/helpers/url-helper';
import { Preloader } from '@shared/ui/preloader';
import { Banner } from '@widgets/seoPage';
import styles from './Subscribe.module.scss';

class Subscribe extends Component {
  constructor(props) {
    super(props);

    this.subscribeRoutes = {
      '/subscribe/email/confirm/': 'subscribe.confirm',
      '/subscribe/email/unsubscribe/': 'subscribe.unsubscribe',
    };

    this.state = {
      subscribe: {},
      isError: false,
      isLoad: false,
    };
  }

  componentDidMount() {
    const { location: { search, pathname } = {} } = this.props;
    const { text_id } = globalStore.get('current_city', {});
    const query = queryString.parse(search) || {};
    const { email, token } = query;
    if (!email || !token) return;
    const path = stripCity(pathname, text_id);
    const route = this.subscribeRoutes[path];

    if (route) {
      const status = route.includes('unsubscribe') ? 'unsubscribe' : 'confirm';
      api(route, query)
        .then(() => {
          globalStore.set('subscribe', { email, token, status });
          this.setState({
            subscribe: {
              email,
              token,
              status,
            },
            isLoad: true,
          });
        })
        .catch((e) => {
          console.log(e);
          this.setState({
            isError: true,
            isLoad: true,
          });
        });
    }
  }

  render() {
    const { subscribe = {}, isError = false, isLoad = false } = this.state;
    const isErrorPage = isError || !subscribe || !Object.keys(subscribe).length;
    if (isErrorPage && isLoad) return <ErrorPage />;
    const {
      t,
      match: {
        params: { action },
      },
    } = this.props;
    const { email = '', status } = subscribe || {};
    const isUnsubscribe = status === 'unsubscribe';

    return (
      <>
        <Banner pathname={`/subscribe/email/${action}`} />
        <PageContainer>
          {!isLoad ? (
            <Preloader />
          ) : (
            <>
              <PageTitle isCenterOnMobile>
                {isUnsubscribe
                  ? t('Subscribe.canceled_title')
                  : t('Subscribe.subscribe_succes')}
              </PageTitle>
              <p className={styles.text}>
                {isUnsubscribe
                  ? t('Subscribe.updates_canceled')
                  : t('Subscribe.updatesWillSent')}{' '}
                <span>{email}</span>
              </p>
            </>
          )}
        </PageContainer>
      </>
    );
  }
}

export default withTranslation()(Subscribe);
