import { useEffect, useState } from 'react';
import { Button } from '@shared/ui/button';
import { useTranslation } from 'react-i18next';
import styles from './cookie.module.scss';

function Cookie() {
  const { t } = useTranslation();
  const [isShown, setShow] = useState(false);

  useEffect(() => {
    if (!localStorage.getItem('PRIVACY_POLICY')) {
      setShow(true);
    }
  }, []);
  const handleClose = () => {
    localStorage.setItem('PRIVACY_POLICY', 'true');
    setShow(false);
  };

  if (!isShown) return null;

  return (
    <div className={styles.root}>
      <p className={styles.text}>{t('Main.cookie')}</p>
      <Button onClick={handleClose}>{t('locale.accept')}</Button>
    </div>
  );
}

export default Cookie;
