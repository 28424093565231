import { useHistory } from 'react-router-dom';

type ParamName = 'userProfilePromo';

export const useQueryParams = () => {
  const {
    location: { search, pathname },
    replace,
  } = useHistory();

  const params = new URLSearchParams(search);

  const get = (name: ParamName) => {
    return params.get(name);
  };

  const set = (name: ParamName, value: string): void => {
    params.append(name, value);
  };

  const getAll = () => {
    return params;
  };

  const remove = (name: ParamName) => {
    params.delete(name);
    replace({
      pathname,
      search: params.toString(),
    });
  };

  return {
    get,
    set,
    remove,
    getAll,
  };
};
