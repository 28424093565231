import { clsx } from 'clsx';
import { ArrowProps } from 'react-multi-carousel/lib/types';
import styles from './carousel.module.scss';

interface IControl extends ArrowProps {
  direction: 'left' | 'right';
}

function Control({ direction, onClick }: IControl) {
  return (
    <button
      onClick={onClick}
      type="button"
      aria-label={direction === 'left' ? 'предыдущий' : 'следующий'}
      className={clsx(styles.arrow, {
        [styles.left]: direction === 'left',
        [styles.right]: direction === 'right',
      })}
    />
  );
}

export default Control;
