import { ReactNode } from 'react';
import NiceModal from '@ebay/nice-modal-react';
import { SideBarContextProvider } from '@widgets/sideBar';
import { modalIds } from '@features/modals';
import loadable from '@loadable/component';

const CartPromocodeErrorModal = loadable(
  () =>
    import('@features/modals/cart-promocode-error-modal').then(
      ({ CartPromocodeErrorModal: Modal }) => Modal
    ),
  { ssr: false }
);

const ComplectationInfoModal = loadable(
  () =>
    import('@features/modals/comlectation-info').then(
      ({ ComplectationInfoModal: Modal }) => Modal
    ),
  { ssr: false }
);

const ForgetComplectationModal = loadable(
  () =>
    import('@features/modals/comlectation-forget').then(
      ({ ForgetComplectationModal: Modal }) => Modal
    ),
  { ssr: false }
);

const ConfirmCityModal = loadable(
  () =>
    import('@features/modals/confirm-city-modal').then(
      ({ ConfirmCityModal: Modal }) => Modal
    ),
  { ssr: false }
);

const SelectCityModal = loadable(
  () =>
    import('@features/modals/select-city-modal').then(
      ({ SelectCityModal: Modal }) => Modal
    ),
  { ssr: false }
);

const DownloadAppsModal = loadable(
  () =>
    import('@features/modals/download-apps-modal').then(
      ({ DownloadAppsModal: Modal }) => Modal
    ),
  { ssr: false }
);

const SelectAddressModal = loadable(
  () =>
    import('@features/modals/select-address-first').then(
      ({ SelectAddressModal: Modal }) => Modal
    ),
  { ssr: false }
);

const VideoModal = loadable(
  () => import('@features/video-modal').then(({ VideoModal: Modal }) => Modal),
  { ssr: false }
);

const ErrorCartModal = loadable(
  () =>
    import('@features//modals/cart-error-modal').then(
      ({ ErrorCartModal: Modal }) => Modal
    ),
  { ssr: false }
);

const PickupErrorUAModal = loadable(
  () =>
    import('@features//modals/cart-error-modal').then(
      ({ PickupErrorUAModal: Modal }) => Modal
    ),
  { ssr: false }
);

NiceModal.register(
  modalIds.complectationInfoModalId,
  NiceModal.create(ComplectationInfoModal)
);
NiceModal.register(
  modalIds.forgetComplectationModalId,
  NiceModal.create(ForgetComplectationModal)
);

NiceModal.register(
  modalIds.ConfirmCityModalId,
  NiceModal.create(ConfirmCityModal)
);
NiceModal.register(
  modalIds.SelectCityModalId,
  NiceModal.create(SelectCityModal)
);

NiceModal.register(
  modalIds.DownloadAppsModalId,
  NiceModal.create(DownloadAppsModal)
);
NiceModal.register(
  modalIds.SelectAddressModalId,
  NiceModal.create(SelectAddressModal)
);
NiceModal.register(modalIds.cartErrorModalId, NiceModal.create(ErrorCartModal));
NiceModal.register(
  modalIds.uaPickupErrorModalId,
  NiceModal.create(PickupErrorUAModal)
);

NiceModal.register(
  modalIds.cartPromocodeErrorModalId,
  NiceModal.create(CartPromocodeErrorModal)
);

NiceModal.register(modalIds.VideoModal, NiceModal.create(VideoModal));

export function ModalProvider({ children }: { children: ReactNode }) {
  return (
    <NiceModal.Provider>
      <SideBarContextProvider>{children}</SideBarContextProvider>
    </NiceModal.Provider>
  );
}
