import autobind from 'autobind-decorator';
import { Component } from 'react';
import { userProfileEmitter } from 'modules/event-emitters';
import ProfileEditComponent from './Edit/Edit';
import { Info } from './Info/Info';

class Profile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      method: 'info',
    };
  }

  componentDidMount() {
    userProfileEmitter.addListener(
      'User.Profile.ChangeTypeAction',
      this.changeTypeListener
    );
  }

  componentWillUnmount() {
    userProfileEmitter.removeListener(
      'User.Profile.ChangeTypeAction',
      this.changeTypeListener
    );
  }

  @autobind
  changeTypeListener(type) {
    this.setState({
      method: type,
    });
  }

  renderContent() {
    const { method } = this.state;
    const { updateAddress, addresses } = this.props;

    switch (method) {
      case 'edit':
        return <ProfileEditComponent />;
      default:
        return <Info addresses={addresses} updateAddress={updateAddress} />;
    }
  }

  render() {
    return this.renderContent();
  }
}

export default Profile;
