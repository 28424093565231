import { useModal } from '@ebay/nice-modal-react';
import { modalIds } from '@features/modals/modal-ids';
import type { ForgetComplectationProps } from '../ui/forget/ForgetComplectationModal';

export const useForgetComplectationModal = () => {
  const modal = useModal(modalIds.forgetComplectationModalId);

  return {
    ...modal,
    show: (args: ForgetComplectationProps) => modal.show(args),
  };
};
