import { Media } from '@shared/lib/media';
import loadable from '@loadable/component';
import { holidayCheck } from '@entities/holyday-decorations';
import { clsx } from 'clsx';
import { useCheckCountries } from '@entities/countries';
import type { IMenuElement } from '@entities/menu';
import { Desktop } from './desktop/Desktop';
import { Mobile } from './mobile/Mobile';
import styles from './header.module.scss';
import MobileNavigation from './mobile/mobileNavigation/MobileNavigation';
import { Logo } from './logo/Logo';

const DecorationSpan = loadable(
  () =>
    import('@entities/holyday-decorations').then(
      ({ DecorationSpan: Decoration }) => Decoration
    ),
  { ssr: false }
);

export function Header({
  countryId,
  isCheckCountries,
  menuList,
  showSelectCity,
}: {
  countryId: number;
  isCheckCountries: ReturnType<typeof useCheckCountries>;
  menuList: IMenuElement[];
  showSelectCity: () => void;
}) {
  const holydayNameForLogo = holidayCheck({
    countryId,
    type: 'logo',
  });
  const logo = (
    <Logo
      holydayName={holydayNameForLogo}
      isCheckCountries={isCheckCountries}
    />
  );

  const holydayNameForHeader = holidayCheck({
    countryId,
    type: 'header',
  });
  const decoration = {
    left: holydayNameForHeader && (
      <DecorationSpan position="left" holidayName={holydayNameForHeader} />
    ),
    right: holydayNameForHeader && (
      <DecorationSpan position="right" holidayName={holydayNameForHeader} />
    ),
  };
  return (
    <>
      <Media greaterThanOrEqual="notebook">
        {(className, renderChildren) => {
          return renderChildren ? (
            <header
              id="main-header"
              className={clsx(className, styles.desktop)}
            >
              <Desktop
                showSelectCity={showSelectCity}
                decoration={decoration}
                logo={logo}
              />
            </header>
          ) : null;
        }}
      </Media>
      <Media lessThan="notebook">
        {(className, renderChildren) => {
          return renderChildren ? (
            <header id="main-header" className={clsx(className, styles.mobile)}>
              <Mobile decoration={decoration} logo={logo} />
              <MobileNavigation menuList={menuList} />
            </header>
          ) : null;
        }}
      </Media>
    </>
  );
}
