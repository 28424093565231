import {
  DeliveryCityAvg,
  deliverySliceSelectors,
  setDeliveryAddress,
  useGetAddressQuery,
} from '@entities/delivery';
import { useTranslation } from 'react-i18next';
import { DeliveryCheck } from '@features/delivery-check';
import CustomText from 'src/frontend/Components/CustomText/CustomText';
import ErrorComponent from 'src/frontend/Components/ErrorComponent/ErrorComponent';
import { Breadcrumbs } from '@shared/ui/breadcrumbs';
import CustomHelmet from 'src/frontend/Components/CustomHelmet';
import { useCurrency } from '@entities/countries';
import { DeliveryMap } from '@features/delivery-map';
import { Suggest } from '@features/address-suggest';
import { Media } from '@shared/lib/media';
import { useCityAndLang } from '@shared/lib/use-city-and-lang';
import { useAppDispatch, useAppSelector } from '@shared/lib/store';
import { useSelectCity, useSelectCityInfo } from '@entities/cities';
import { useEffect, useState } from 'react';
import { PageContainer } from '@shared/ui/content-containers';
import { skipToken } from '@reduxjs/toolkit/query';
import { Banner } from '@widgets/seoPage';
import { DeliveryHeader } from './delivery-header';
import styles from './delivery.module.scss';

function Delivery() {
  const { t } = useTranslation();

  const { is_delivery } = useSelectCity();
  const cityInfo = useSelectCityInfo();

  const {
    city_name: cityName,
    latitude: lat,
    longitude: lng,
  } = useSelectCity();

  const dispatch = useAppDispatch();

  const { fullAddress, addressError } = useAppSelector(
    deliverySliceSelectors.selectFullAddress
  );

  const { city, lang } = useCityAndLang();

  const [newAddress, setNewAddress] = useState<string | number[] | null>(null);

  const params = newAddress
    ? { coordsOrString: newAddress, cityName, cityTextId: city, lang }
    : skipToken;
  const {
    data: newFullAddress,
    isLoading,
    isFetching,
  } = useGetAddressQuery(params);

  const setAddress = (address: string) => {
    setNewAddress(address);
  };

  const setCurrentCenter = (coors: number[] | null) => {
    setNewAddress(coors);
  };

  useEffect(() => {
    if (newFullAddress) {
      dispatch(setDeliveryAddress(newFullAddress));
    }
  }, [newFullAddress]);

  const currency = useCurrency();

  const { delivery, delivery_card: deliveryCard } = cityInfo;
  const deliveryAvgProps = {
    priceFrom: deliveryCard.delivery_price,
    priceTo: delivery.delivery_price,
    orderMin: delivery.order_min,
    timeFrom: delivery.from ?? '-',
    timeTo: delivery.to ?? '-',
    currency,
  };

  if (!is_delivery) {
    return <ErrorComponent />;
  }

  return (
    <>
      <Banner pathname="/delivery" />
      <CustomHelmet title={t('localization.Delivery')} />
      <Media greaterThanOrEqual="notebook" className={styles.pageDecoration}>
        {null}
      </Media>
      <PageContainer>
        <Breadcrumbs
          links={[
            {
              text: t('localization.Delivery'),
            },
          ]}
        />
        <DeliveryHeader />
        <div className={styles.pageDeliveryBlock}>
          <div className={styles.pageDeliverySearch}>
            <DeliveryCityAvg {...deliveryAvgProps} />
            <div className={styles.deliveryCheck}>
              <Media greaterThanOrEqual="notebook">
                <span className={styles.addressTitle}>
                  {t('Delivery.your_address')}
                </span>
              </Media>
              <DeliveryCheck
                suggest={
                  <Suggest
                    fullAddress={fullAddress}
                    setAddress={setAddress}
                    cityCoords={{ lat, lng }}
                    cityName={cityName}
                    lang={lang}
                    placeholder={t('localization.Enter street or subway')}
                  />
                }
              />
            </div>
          </div>
        </div>
        <div className={styles.deliveryMap}>
          <DeliveryMap
            fullAddress={fullAddress}
            addressError={addressError}
            setCurrentCenter={setCurrentCenter}
            cityCoords={{ lat, lng }}
            isNowFetching={isLoading || isFetching}
          />
        </div>
        <CustomText />
      </PageContainer>
    </>
  );
}

export default Delivery;
