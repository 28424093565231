import { ReactNode } from 'react';
import styles from './styles.module.scss';

export function TitleWithLines({ children }: { children: ReactNode }) {
  return (
    <h3 className={styles.root}>
      <span className={styles.title}>{children}</span>
    </h3>
  );
}
