import { clsx } from 'clsx';
import { useTranslation } from 'react-i18next';
import styles from './filterTags.module.scss';
import { IFilterTagsProps } from '../model/types';

function FilterTags({ filters, changeQuery, selectedTag }: IFilterTagsProps) {
  const { t } = useTranslation();

  const tagStyles = clsx(styles.tag, {
    [styles.active]: !selectedTag,
  });

  return (
    <div className={styles.tagsContainer}>
      <button className={tagStyles} type="button" onClick={() => changeQuery()}>
        {t('localization.all')}
      </button>
      {filters.map(({ text_id, name }) => {
        const categoryTagStyles = clsx(styles.tag, {
          [styles.active]: selectedTag === text_id,
        });

        return (
          <button
            type="button"
            key={text_id}
            onClick={() => changeQuery(text_id)}
            className={categoryTagStyles}
          >
            {name}
          </button>
        );
      })}
    </div>
  );
}

export default FilterTags;
