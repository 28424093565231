import { MediaContextProvider } from '@shared/lib/media';
import { RoutesWithSubRoutes } from '@shared/lib/routing';
import routes from './routes';
import { ErrorCatcher } from './error-boundary';

function App() {
  return (
    <MediaContextProvider>
      <ErrorCatcher>
        <RoutesWithSubRoutes routes={routes} />
      </ErrorCatcher>
    </MediaContextProvider>
  );
}

export default App;
