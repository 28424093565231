import { useRef } from 'react';

export function useCityChange(currentCityTextId: string) {
  const hasCityChanged = useRef(false);
  if (typeof window === 'undefined') {
    return { hasCityChanged: hasCityChanged.current };
  }
  const cityIdFromStorage = localStorage.getItem('currentCityTextId');
  if (!cityIdFromStorage) {
    localStorage.setItem('currentCityTextId', currentCityTextId);
  } else if (cityIdFromStorage !== currentCityTextId) {
    localStorage.setItem('currentCityTextId', currentCityTextId);
    hasCityChanged.current = true;
  }
  return { hasCityChanged: hasCityChanged.current };
}
