import globalStore from 'modules/global-store';
import storeHelper from '@entities/stores/lib/store-helper';
import { TFunction } from 'i18next';
import { getMinutesAmount, IStore } from '@entities/stores';
import {
  addDays,
  addMinutes,
  eachMinuteOfInterval,
  eachDayOfInterval,
  endOfDay,
  format,
  isAfter,
  isSameDay,
  max,
  parse,
  roundToNearestMinutes,
  startOfDay,
  subMinutes,
  formatDateWithLocale,
  isSameMinute,
  formatHelper,
} from '@shared/lib/date';

interface IGetAvailableTimeIntervals {
  selectedDate: Date;
  serverTime: Date;
  storeFrom: string;
  storeTo: string;
  additionalTime: number;
  pickupBeforeClose: number;
}

interface IGetWorkingHours {
  selectedDate: Date;
  store: IStore | null;
  deliveryTime: number | null;
}

interface IGetTimeList {
  selectedDate: Date;
  serverTime: Date | null;
  store: IStore | null;
  deliveryTime: number | null;
}

const setInterval = (start: Date, end: Date) => {
  return { start, end };
};

const setListOption = (value: string, text?: string) => {
  if (!text) return { value, text: value };
  return { value, text };
};

const getAvailableTimeIntervals = ({
  selectedDate,
  serverTime,
  storeFrom,
  storeTo,
  additionalTime,
  pickupBeforeClose,
}: IGetAvailableTimeIntervals) => {
  const storeFromDate = parse(storeFrom, formatHelper.time, selectedDate);
  const storeToDate = parse(storeTo, formatHelper.time, selectedDate);
  const isAfterYesterday = isAfter(storeFromDate, storeToDate);
  const isToday = isSameDay(selectedDate, serverTime);
  const currentAvailableTime = addMinutes(serverTime, additionalTime);

  const minAvailableStartDate =
    storeFrom === storeTo
      ? max([selectedDate, currentAvailableTime])
      : addMinutes(storeFromDate, additionalTime);

  const maxAvailableEndDate =
    storeFrom === storeTo
      ? endOfDay(selectedDate)
      : subMinutes(storeToDate, pickupBeforeClose);

  if (!isToday && !isAfterYesterday)
    return [setInterval(minAvailableStartDate, maxAvailableEndDate)];

  if (isToday && isAfterYesterday) {
    if (currentAvailableTime < maxAvailableEndDate)
      return [
        setInterval(currentAvailableTime, maxAvailableEndDate),
        setInterval(minAvailableStartDate, endOfDay(selectedDate)),
      ];

    if (currentAvailableTime > minAvailableStartDate)
      return [setInterval(currentAvailableTime, endOfDay(selectedDate))];

    return [setInterval(minAvailableStartDate, endOfDay(selectedDate))];
  }

  if (isAfterYesterday)
    return [
      setInterval(startOfDay(selectedDate), maxAvailableEndDate),
      setInterval(minAvailableStartDate, endOfDay(selectedDate)),
    ];

  if (currentAvailableTime > maxAvailableEndDate && !isAfterYesterday)
    return [];

  return [
    setInterval(
      max([minAvailableStartDate, currentAvailableTime]),
      maxAvailableEndDate
    ),
  ];
};

const getWorkingHours = ({
  store,
  selectedDate,
  deliveryTime,
}: IGetWorkingHours) => {
  if (store) {
    const { worktime_from: storeFrom, worktime_to: storeTo } =
      storeHelper.getWorkTime(store, selectedDate) || {};
    const { cooking_time, pickup_before_close } = store;
    const additionalTime = getMinutesAmount(cooking_time, 'defaultCookingTime');
    const pickupBeforeClose = getMinutesAmount(
      pickup_before_close,
      'defaultPickupBeforeClose'
    );

    return { storeFrom, storeTo, additionalTime, pickupBeforeClose };
  }
  const { delivery = null } = globalStore.take('city_info');
  const {
    from: storeFrom,
    to: storeTo,
    avg_delivery_time: avgDeliveryTime,
  }: { from?: string; to?: string; avg_delivery_time: number } = delivery;
  const additionalTime = deliveryTime || avgDeliveryTime;

  return {
    storeFrom,
    storeTo,
    additionalTime,
  };
};

const createTimeListFromInterval = ({
  start,
  end,
}: {
  start: Date;
  end: Date;
}) => {
  const step = 15;
  // Ближайшая дата от начала интервала, кратная {step} минутам
  const nearestRoundDate = roundToNearestMinutes(start, {
    nearestTo: step,
  });

  // Если полученная дата меньше начала интервала - прибавим к ней {step} минут
  const startDateForList =
    nearestRoundDate < start
      ? addMinutes(nearestRoundDate, step)
      : nearestRoundDate;

  if (isSameMinute(startDateForList, end) || startDateForList > end)
    return [start, end];

  // Интервал, начинающийся с ближайшей даты кратной {step} минутам
  const listInterval = setInterval(startDateForList, end);

  // Массив дат в этом интервале, с шагом {step} минут
  const list = eachMinuteOfInterval(listInterval, { step });

  // Если массив пустой - возвращаем массив из начала и конца интервала
  if (!list.length) return [start, end];

  // Если первый элемент массива совпадает с минимальным временем для заказа
  // (параметр start кратен step минутам, т.е. 00:00, 00:{step}, 00:{1 * step} и т.д.)
  if (isSameMinute(list[0], start)) {
    return list;
  }

  // Иначе добавляем в начало массива минимально допустимое время
  return [start, ...list];
};

const getTimeList = ({
  selectedDate,
  serverTime,
  store,
  deliveryTime,
}: IGetTimeList) => {
  if (!selectedDate || !serverTime) return [];
  const {
    storeFrom,
    storeTo,
    additionalTime,
    pickupBeforeClose = 0,
  } = getWorkingHours({ store, selectedDate, deliveryTime });
  if (!storeFrom || !storeTo) return [];

  // Вычислим интревалы работы магазина в заданную дату (количество интервалов от 0 до 2)
  const availableIntervals = getAvailableTimeIntervals({
    selectedDate,
    serverTime,
    storeFrom,
    storeTo,
    additionalTime,
    pickupBeforeClose,
  });

  return availableIntervals
    .map(createTimeListFromInterval)
    .flat()
    .map((time) => {
      const timeString = format(time, formatHelper.time);
      return setListOption(timeString);
    });
};

const getDateList = (
  language: string,
  t: TFunction,
  serverTime: Date | null
) => {
  const startDate = serverTime ?? new Date();

  const dateList = [
    setListOption(format(startDate, formatHelper.day), t('locale.today')),
    setListOption(
      format(addDays(startDate, 1), formatHelper.day),
      t('locale.tomorrow')
    ),
  ];
  const list = eachDayOfInterval(
    setInterval(addDays(startDate, 2), addDays(startDate, 7))
  );
  return dateList.concat(
    list.map((date) => {
      return setListOption(
        formatDateWithLocale(date, formatHelper.day, language),
        formatDateWithLocale(date, formatHelper.dayText, language)
      );
    })
  );
};

export { getTimeList, getDateList, formatHelper };
