import { baseApi } from '@shared/api';
import { IShortCity } from '../model/types';

const CitiesApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getCities: build.query<
      { data: { city: IShortCity[] } },
      {
        country_id: number;
        lang: string;
        short?: 1;
        tree?: 1;
      }
    >({
      query: (params) => ({
        url: '/endpoint/geo/list_city',
        method: 'GET',
        params: {
          ...params,
          short: 1,
        },
      }),
    }),
  }),
});

export const { useGetCitiesQuery } = CitiesApi;
