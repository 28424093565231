/**
 * Created by rebu on 03.02.17.
 */

const obj = {
  PHONE_CODE_EXPIRED: 1,
  PHONE_CODE_INVALID: 2,
  INVALID_COUNTRY_CODE: 3,

  ORDER_INCORRECT_PRICE: 100,
  ORDER_NOT_DELIVERY: 101,
  ORDER_INCORRECT_ORDER_ID: 102,
  ORDER_EXIST: 103,
  ORDER_STORE_NOT_WORK: 104,
  ORDER_NOT_FOUND_PRODUCT: 105,
  ORDER_NOT_FOUND_TOPPING: 106,

  ORDER_NOT_FOUND: 110,
  ORDER_HASH_NOT_GENERATED: 111,

  ORDER_PHONE_PAY_CODE_INVALID: 201,
  ORDER_PHONE_PAY_CODE_EXPIRED: 202,
  ORDER_PHONE_PAY_CODE_INSUFFICIENT: 203,
  ORDER_PROMO_CODE_INVALID: 210,

  SERVICE1C_INVALID: 300,
  COURIER_ORDER_PICKUP: 301,

  REQUEST_INVALID_FORM: 400,
  REQUEST_NOT_AUTH: 401,
  USER_PHONE_UNDEFINED: 405,
  USER_PHONE_UNCONFIRMED: 406,

  REORDER_CART_NOT_EMPTY: 1001,
} as const;

export default obj;
