import { PropsWithChildren } from 'react';
import { IProduct } from 'types/product';
import { SmartLink } from '@shared/ui';
import { openCardModal } from '../../NewCartOrder/Components/CartProductsBlock/utils';

interface ILinkHandler {
  product: IProduct;
  link: string;
  cartItemId: number;
}

function LinkWrapper({
  link,
  product,
  cartItemId,
  children,
}: PropsWithChildren<ILinkHandler>) {
  return (
    <SmartLink
      itemType="https://schema.org/url"
      itemScope
      href={link}
      onClick={(e) => {
        openCardModal(e, {
          product,
          cartItemId,
        });
      }}
      data-id={product.id}
    >
      {children}
    </SmartLink>
  );
}

function LinkHandler({
  children,
  cartItemId,
  product,
  link = '',
}: PropsWithChildren<ILinkHandler>) {
  return link ? (
    <LinkWrapper product={product} link={link} cartItemId={cartItemId}>
      {children}
    </LinkWrapper>
  ) : (
    children
  );
}

export default LinkHandler;
