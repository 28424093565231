import { baseApi } from '@shared/api';
import { IUserAddress, IUserOrder } from '../model/types';

export const userApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getUserAddresses: build.query<
      { data: { addresses: IUserAddress[] } },
      { city: string; lang: string }
    >({
      query: (query) => ({
        url: `/endpoint/user/addresses`,
        method: 'GET',
        params: query,
        headers: { authorization: 'true' },
      }),
    }),
    getUserOrders: build.query<
      { data: { orders: IUserOrder[] } },
      { lang: string }
    >({
      query: (params) => ({
        url: '/endpoint/order2/get_orders',
        method: 'GET',
        params,
        headers: { authorization: 'true' },
      }),
    }),
  }),
});

export const { useGetUserAddressesQuery, useGetUserOrdersQuery } = userApi;
