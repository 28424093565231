import { clsx } from 'clsx';
import style from './button.module.scss';

interface IButton
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  variant?: 'contained' | 'outlined';
  isFullWidth?: boolean;
}

export function Button({
  className,
  children,
  isFullWidth = false,
  variant = 'outlined',
  ...props
}: IButton) {
  return (
    <button
      className={clsx(style.swButton, className, {
        [style.fullWidth]: isFullWidth,
        [style.contained]: variant === 'contained',
        [style.outlined]: variant === 'outlined',
      })}
      type="button"
      {...props}
    >
      {children}
    </button>
  );
}
