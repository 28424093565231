import { useEffect, useState } from 'react';
import { useIsBannerClosed } from './useIsBannerClosed';
import type { IBanner, OsType } from './types';

const getCurrentDevice = () => {
  const { userAgent } = navigator;
  if (/iPhone/i.test(userAgent)) return 'iPhone';
  if (/android/i.test(userAgent)) return 'android';
  return 'another';
};

export const useBannerData = ({ bannerData }: { bannerData: IBanner }) => {
  const { visible, closeBanner } = useIsBannerClosed();
  const [data, setData] = useState<OsType | null>(null);

  useEffect(() => {
    const currentDevice = getCurrentDevice();
    const { ios, android } = bannerData;
    if (currentDevice === 'iPhone' && ios) {
      setData(ios);
      return;
    }
    if (currentDevice === 'android' && android) {
      setData(android);
    }
  }, [bannerData]);

  return {
    visible: visible && !!data?.image,
    image: data?.image,
    link: data?.link,
    closeBanner,
  };
};
