import './CardWok.scss';
import WokRepository from 'frontend/Components/Products/CardWok/wok-constructor/WokRepository';
import bind from 'autobind-decorator';
import React, { Component } from 'react';
import * as session from 'modules/session';
import Scroll from 'react-scroll';
import { WokConstructorCard } from '@features/wok-constructor';
import WokConstructor from './wok-constructor/WokConstructor';
// import Emitter from '../../NewCartOrder/Emitters';
import Card from '../Card/Card';
import addStocks from '../CardStock/addStocks';

class CardWok extends Component {
  constructor(props) {
    super(props);
    this.repository = WokRepository.new(props.products, props.group);
  }

  // TODO: кажется это не нужно
  // componentDidMount() {
  //   Emitter.addListener('CART_CHANGE', this.reRender);
  // }

  componentWillReceiveProps(nextProps) {
    this.repository = WokRepository.new(nextProps.products, nextProps.group);
  }

  // TODO: и это кажется тоже не нужно
  // componentWillUnmount() {
  //   Emitter.removeListener('CART_CHANGE', this.reRender);
  // }

  // @bind reRender(callback) {
  //   if (typeof callback !== 'function') {
  //     callback = () => undefined;
  //   }
  //   this.forceUpdate(callback);
  // }

  @bind openWokConstructor(event) {
    event.preventDefault();
    const boundTop = this.blockConstructor.getBoundingClientRect().top;
    const scrollTop = window.pageYOffset;

    Scroll.animateScroll.scrollTo(boundTop + scrollTop - 60, {
      duration: 300,
      delay: 150,
    });
  }

  render() {
    const { products, group } = this.props;
    const cartItems = session.cartItems();
    const hasWoks = this.repository.hasWoks();
    const hasWokConstructor = this.repository.hasWokForConstructor();

    const renderedWoks = this.repository.collectWoks((wok) => (
      <Card
        key={wok.id}
        product={wok}
        view_type="grid"
        position="catalog"
        cart_item={cartItems.find((item) => item.id === wok.id)}
      />
    ));
    const woksWithStocks = addStocks({
      CardComponents: renderedWoks,
      itemsGrid: 1,
      menuId: 709,
    });
    return (
      <>
        <div className="card-wok__wrapper">
          {hasWoks && (
            <div className="card-wok__products">
              {woksWithStocks}
              {hasWokConstructor && (
                <div className="card-wrapper--grid">
                  <WokConstructorCard action={this.openWokConstructor} />
                </div>
              )}
            </div>
          )}
        </div>
        <div ref={(c) => (this.blockConstructor = c)}>
          <WokConstructor products={products} group={group} mobile={false} />
        </div>
      </>
    );
  }
}

export default CardWok;
