import { clsx } from 'clsx';
import { DetailedHTMLProps, HTMLAttributes, PropsWithChildren } from 'react';
import styles from './styles.module.scss';

interface IPageTitleProps
  extends DetailedHTMLProps<
    HTMLAttributes<HTMLHeadingElement>,
    HTMLHeadingElement
  > {}

export function Subtitle({
  children,
  className,
}: PropsWithChildren<IPageTitleProps>) {
  const style = clsx(styles.root, className);

  return <h2 className={style}>{children}</h2>;
}
