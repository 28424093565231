import { clsx } from 'clsx';
import { useTranslation } from 'react-i18next';
import globalStore from 'modules/global-store';
import { formaterTime, parseTimeWithSeconds } from '@shared/lib/date';
import { checkAvailableNow } from '../../lib/checkAvailableNow';
import style from './availableInfo.module.scss';

export interface IAvailableInfo {
  available: boolean;
  available_from: string;
  available_to: string;
  className?: string;
}

export function AvailableInfo({
  available,
  available_from,
  available_to,
  className,
}: IAvailableInfo) {
  const { t } = useTranslation();
  const { locale } = globalStore.get('current_city');

  const styles = clsx(style.root, className);

  if (!available) {
    return (
      <div className={styles}>
        {t('AvailableInfo.Product_is_not_available')}
      </div>
    );
  }

  if (!available_from || !available_to) return null;

  const parsedFrom = parseTimeWithSeconds(available_from);
  const parsedTo = parseTimeWithSeconds(available_to);

  const formatedFrom = formaterTime(parsedFrom);
  const formatedTo = formaterTime(parsedTo);

  const isAvailableNow = checkAvailableNow(parsedFrom, parsedTo, locale);

  if (isAvailableNow) return null;

  return (
    <div className={styles}>{`${t(
      'AvailableInfo.You_can_order_from'
    )} ${formatedFrom} ${t('AvailableInfo.to')} ${formatedTo}`}</div>
  );
}
