import { useResizeWindow } from './hooks';

export const withBreakpoints = <P extends object>(
  Component: React.ComponentType<P>
) => {
  return function EnhancedComponent(props: P) {
    const breakpoints = useResizeWindow();
    return <Component {...props} {...breakpoints} />;
  };
};
