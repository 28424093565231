import './Filter.scss';
import autobind from 'autobind-decorator';
import { Component } from 'react';
import { reviewsFilterEmitter } from 'modules/event-emitters';
import * as session from 'modules/session';
import storeHelper from '@entities/stores/lib/store-helper';
import { withTranslation } from 'react-i18next';

class ReviewsFilter extends Component {
  constructor(props) {
    super(props);

    const { city_stores } = props;

    this.state = {
      city_stores: city_stores || [],
      city: session.get('city'),
      store_id: 0,
    };
  }

  componentWillReceiveProps(nextProps) {
    const { city_stores } = nextProps;

    this.setState({ city_stores });
  }

  @autobind
  changeFilter({ target }) {
    const newState = { ...this.state, [target.name]: target.value };

    reviewsFilterEmitter.emit('filter', {
      city: newState.city,
      store_id: newState.store_id,
    });

    this.setState(newState);
  }

  render() {
    const { city_stores } = this.state;
    const { t } = this.props;
    return (
      <div className="reviews-filter form">
        <div className="input-group--100">
          <div className="input-container">
            <label htmlFor="store">{t('Filter.select_store')}</label>
            <select
              name="store_id"
              className="form__select form__select--reviews material"
              value={this.state.store_id}
              onChange={this.changeFilter}
              autoComplete="false"
            >
              <option value="0">{t('Filter.all_reviews')}</option>
              {city_stores.map((store, index) => {
                return (
                  <option key={index} value={store.id}>
                    {storeHelper.getAddress(store)}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(ReviewsFilter);
