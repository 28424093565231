import { Route, Switch, RouteComponentProps } from 'react-router-dom';
import { IServerDataContext, ServerDataContext } from './loader-data';

export interface IRoute {
  component: React.ComponentType<
    RouteComponentProps & {
      routes?: IRoute[];
    }
  >;
  path?: string;
  exact?: boolean;
  routes?: IRoute[];
  loader?: () => Promise<unknown>;
}
export function RoutesWithSubRoutes({ routes }: { routes: IRoute[] }) {
  const getContextValue = (params: IServerDataContext) => ({
    ...params,
  });

  return (
    <Switch>
      {routes.map((route, i) => {
        return (
          <Route
            // eslint-disable-next-line react/no-array-index-key
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => {
              // pass the sub-routes down to keep nesting
              return (
                <ServerDataContext.Provider
                  value={getContextValue({
                    staticContext:
                      props.staticContext as IServerDataContext['staticContext'],
                    loader: route.loader,
                    daddy: route.component.name,
                  })}
                >
                  <route.component {...props} routes={route.routes} />
                </ServerDataContext.Provider>
              );
            }}
          />
        );
      })}
    </Switch>
  );
}
