import './MainMobile.scss';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import Promotions from 'frontend/Components/Main/TrioOnMainPage/TrioOnMainPage';
import CustomText from 'frontend/Components/CustomText/CustomText';
import { Banner } from '@widgets/seoPage';
import { PromoBlocks } from '../promo/ui/promo-blocks/promo-blocks';
import { Publication } from '../publication';
import { MainSlider } from '../main-slider';

function MainMobile(props) {
  const { t } = useTranslation();
  const { banner, stocks, promoLinks, news, mainpage } = props;
  const renderNewsSlider = () => {
    const normalizeNews = news.map((item) => {
      return {
        ...item,
        publication_date: item.publication_from,
      };
    });
    return (
      <Publication
        list={normalizeNews}
        titleText={t('Main.news')}
        bottomLinkText={t('Main.all_news')}
        href="news"
      />
    );
  };
  const renderMainSlider = () => {
    return <MainSlider banner={banner} />;
  };

  const renderStocks = () => {
    if (!stocks || !stocks.length) return null;
    return <Promotions stocks={stocks} />;
  };

  const renderPromoBlocks = () => {
    return <PromoBlocks promoLinks={promoLinks} />;
  };

  const activeBlocks = mainpage.filter((block) => block.active);

  const activeBlocksKeys = {
    slider: renderMainSlider(),
    stocks: renderStocks(),
    promos: renderPromoBlocks(),
    news: renderNewsSlider(),
  };

  return (
    <>
      <Banner pathname="/" />
      <div className="page-main">
        {activeBlocks.map(({ name }) => {
          if (!name) return null;
          return <Fragment key={name}>{activeBlocksKeys[name]}</Fragment>;
        })}
        <CustomText />
      </div>
    </>
  );
}

export default MainMobile;
