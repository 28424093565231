import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { resetComplectation } from '@entities/complectation';
import { useAppDispatch } from '@shared/lib/store';
import { LinkButton } from '@shared/ui/button';
import styles from './empty.module.scss';

function Empty() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(resetComplectation());
  }, []);

  return (
    <div className={styles.root}>
      <div className={styles.titleContainer}>
        <div className={styles.title}>{t('Empty.Cart is empty left')}</div>
        <div className={styles.title}>{t('Empty.Cart is empty right')}</div>
      </div>
      <LinkButton href="/menu">{t('Empty.Go to menu')}</LinkButton>
    </div>
  );
}

export default Empty;
