import { useTranslation } from 'react-i18next';
import { analytics } from 'frontend/analytics/analytics';
import Emitter from 'frontend/Components/NewCartOrder/Emitters';
import { Button } from '@shared/ui/button';
// eslint-disable-next-line css-modules/no-unused-class
import modalStyles from 'src/frontend/Components/NewCartOrder/Modals/main-card-modal/mainCartModal.module.scss';
import { ModalParagraph } from '@shared/ui/modal-paragraph';
import { resetCart } from '../../api';
import styles from './resetCartModal.module.scss';

export function ResetCartModal() {
  const { t } = useTranslation();
  const closeModal = () => {
    Emitter.emit('MODAL_CLOSE');
  };

  const confirm = () => {
    resetCart().then(() => {
      Emitter.emit('MODAL_CALL', { modal: false });
      analytics.clearCartDesire();
    });
  };

  return (
    <div className={styles.resetCartModal}>
      <ModalParagraph>{t('locale.clean_the_basket')}</ModalParagraph>
      <div className={modalStyles.btnsContainer}>
        <Button onClick={confirm}>{t('locale.clean')}</Button>
        <Button onClick={closeModal}>{t('locale.cancel')}</Button>
      </div>
    </div>
  );
}

export default ResetCartModal;
