import { useEffect, useState } from 'react';
import * as session from 'modules/session';
import { ICartItem } from 'types/cartTypes';
import { clsx } from 'clsx';
import Emitters from 'frontend/Components/NewCartOrder/Emitters';
import { SmartLink } from '@shared/ui';
import styles from './cartLink.module.scss';
import Basket from './assets/basket.svg';

function CartLink({ isMobile = false }) {
  const [cartItems, setCartItems] = useState(
    session.get('cart').items as ICartItem[]
  );

  const getCartCount = () => {
    return cartItems.reduce((count, item) => {
      return count + item.qty;
    }, 0);
  };

  const cartChange = () => {
    setCartItems(session.get('cart').items);
  };

  useEffect(() => {
    Emitters.addListener('CART_CHANGE', cartChange);

    return () => {
      Emitters.removeListener('CART_CHANGE', cartChange);
    };
  }, []);

  const cartCount = getCartCount();

  return (
    <SmartLink
      href="/cart"
      className={clsx(styles.root, { [styles.rootMobile]: isMobile })}
    >
      <Basket
        width={30}
        height={30}
        className={clsx(styles.svg, { [styles.svgMobile]: isMobile })}
      />
      {cartCount > 0 && (
        <span className={styles.notification}>{cartCount}</span>
      )}
    </SmartLink>
  );
}

export { CartLink };
