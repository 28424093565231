import { memo, useEffect, useState } from 'react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { parse } from '@shared/lib/date';
import useServerTime from 'frontend/data/time/use-server-time';
import { IStore } from '@entities/stores';
import { IListOptions } from 'frontend/data/time/types';
import { clsx } from 'clsx';
import { getTimeList, getDateList, formatHelper } from './dateTimeHelper';
import type { IChangeEventImitation } from '../../OrderingForm';

interface IDateTimeSelect {
  field: any;
  date: string;
  handleChangeForm: (
    event: React.ChangeEvent<HTMLSelectElement> | IChangeEventImitation
  ) => void;
  store: IStore | null;
  deliveryTime: number | null;
}

const valueInList = (
  value: string,
  list: { value: string; text: string }[]
): boolean => {
  return !!list.find(({ value: listValue }) => listValue === value);
};

function DateTimeSelect({
  field,
  deliveryTime,
  handleChangeForm,
  store,
  date: selectedDateString,
}: IDateTimeSelect) {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { name, label, invalid, fieldsetClassName = '', value } = field;
  const isDateSelect = name === 'select_date';
  const { data } = useServerTime();
  const [listToChoose, setListToChoose] = useState<IListOptions>([]);

  useEffect(() => {
    if (!data) return;
    try {
      const serverTime = data
        ? parse(data.server_date, formatHelper.fullDate, new Date())
        : null;

      const selectedDate = selectedDateString
        ? parse(selectedDateString, formatHelper.day, new Date())
        : new Date();

      const newListToChoose = isDateSelect
        ? getDateList(language, t, serverTime)
        : getTimeList({ selectedDate, serverTime, store, deliveryTime });

      setListToChoose(newListToChoose);
    } catch (e) {
      setListToChoose([]);
      console.error(e);
    }
  }, [
    data,
    selectedDateString,
    language,
    deliveryTime,
    isDateSelect,
    name,
    value,
    t,
    store,
    handleChangeForm,
  ]);

  if (!data) return null;

  if (listToChoose.length && (!value || !valueInList(value, listToChoose)))
    handleChangeForm({
      target: {
        name,
        value: listToChoose[0]?.value || '',
      },
    });

  const isStoreClosed = !listToChoose.length;

  const fieldClass = clsx('select-row small-margin', fieldsetClassName, {
    invalid,
  });

  if (isStoreClosed)
    return (
      <fieldset className={fieldClass}>
        {label ? <label htmlFor="">{label}</label> : undefined}
        <p>{t('locale.store_closed')}</p>
      </fieldset>
    );

  return (
    <fieldset className={fieldClass}>
      {label ? <label htmlFor="">{label}</label> : undefined}
      <select onChange={handleChangeForm} name={name} value={value}>
        {listToChoose.map((date) => {
          return (
            <option key={date.value} value={date.value}>
              {date.text}
            </option>
          );
        })}
      </select>
    </fieldset>
  );
}

export default memo(DateTimeSelect);
