import style from './preloader.module.scss';

export function Preloader() {
  return (
    <div className={style.root}>
      <span className={style.first} />
      <span className={style.second} />
      <span className={style.third} />
    </div>
  );
}
