import './Edit.scss';
import './EditMobile.scss';
import autobind from 'autobind-decorator';
import { Component } from 'react';

import { userProfileEmitter } from 'modules/event-emitters';
import * as session from 'modules/session';
import validators from 'modules/form-validators';
import escapeReg from 'modules/helpers/escape-regexp';
import removeUnwantedCharacters from 'modules/helpers/remove-unwanted-characters';
import { withTranslation } from 'react-i18next';
import ResponseError from 'modules/response/error';
import api from 'modules/helpers/api';
import { Button } from '@shared/ui/button';
import { clsx } from 'clsx';
import { withCheckCountries } from '@entities/countries';
import {
  formaterBirthday,
  formaterBirthdayToISO,
  parseDate,
} from '@shared/lib/date';
import { getTargetValue } from './lib';
import Masked from '../../Masked';

class ProfileEdit extends Component {
  // @todo - to locale
  DATE_FORMAT = 'DD.MM.YYYY';

  constructor(props) {
    super(props);
    const user = session.get('user');
    let birthDay = '';
    try {
      if (user.birth_day) {
        birthDay = formaterBirthday(user.birth_day);
      }
    } catch (e) {
      console.error(e);
    }

    this.state = {
      formSubmitted: false,
      invalidFields: {},
      formValidators: {
        name: 'string:128',
      },
      form: {
        name: user.name || '',
        phone: user.phone || '',
        birth_day: birthDay,
        sms_subscription: user.sms_subscription || 0,
        phone_subscription: user.phone_subscription || 0,
      },
    };
  }

  @autobind
  onChangeHandler({ target }) {
    const { form } = this.state;
    if (target.name === 'name')
      removeUnwantedCharacters(target, escapeReg(' -'));

    const newForm = getTargetValue(target, form);

    this.setState({
      form: Object.assign(form, newForm),
    });
  }

  @autobind
  cancelHandler() {
    userProfileEmitter.emit('User.Profile.ChangeTypeAction', 'info');
  }

  @autobind
  birthDateValidate(value) {
    const { invalidFields } = this.state;
    if (value) {
      if (invalidFields.birth_day) {
        delete invalidFields.birth_day;
      }
    } else invalidFields.birth_day = true;
    this.setState({ invalidFields });
  }

  @autobind
  saveHandler() {
    const { form, invalidFields } = this.state;
    const data = {
      sms_subscription: form.sms_subscription ? 1 : 0,
      phone_subscription: form.phone_subscription ? 1 : 0,
      name: form.name.trim(),
      birth_day: form.birth_day ? formaterBirthdayToISO(form.birth_day) : '',
    };

    if (Object.keys(invalidFields).length > 0) {
      this.setState({
        formSubmitted: true,
      });
    } else {
      api('user.update', data)
        .then(({ user }) => {
          session.set('user', user);
          userProfileEmitter.emit('User.Profile.ChangeTypeAction', 'info');
        })
        .catch((e) => {
          const error = new ResponseError(e);
          const { birth_day: invalid_birth } = error.getFields();
          if (invalid_birth) {
            this.setState({ invalidFields, formSubmitted: true });
            this.birthDateValidate(!invalid_birth);
          } else console.log(error);
        });
    }
  }

  @autobind
  validateMessage(name) {
    const { formSubmitted, invalidFields } = this.state;
    return formSubmitted && invalidFields[name];
  }

  checkIsFutureDate(value) {
    const { invalidFields } = this.state;
    this.setState({ formSubmitted: true });
    const date = parseDate(value);
    const now = new Date();
    const compare = date < now;
    if (!compare) {
      invalidFields.birth_day = true;
      this.validateMessage('birth_day');
    } else {
      delete invalidFields.birth_day;
      this.saveHandler();
    }
    this.setState({ invalidFields });
  }

  render() {
    const { form, formValidators, invalidFields } = this.state;
    const { isUkraine, t } = this.props;
    validators.validate(form, formValidators, invalidFields);
    return (
      <div className="user-profile__container">
        <div
          className={`input-container ${
            this.validateMessage('name') ? 'error' : undefined
          }`}
        >
          {this.validateMessage('name') ? (
            <label>{t('Edit.name_is_invalid')}</label>
          ) : (
            <label>{t('Edit.name')}</label>
          )}
          <input
            type="text"
            autoComplete="off"
            name="name"
            defaultValue={form.name.trim()}
            onChange={this.onChangeHandler}
          />
        </div>

        <div className="user-profile__edit">
          <div
            className={`input-container ${
              this.validateMessage('birth_day') ? 'error' : undefined
            }`}
          >
            {this.validateMessage('birth_day') ? (
              <label>{t('Edit.date_is_invalid')}</label>
            ) : (
              <label>{t('Edit.birthday')}</label>
            )}
            <Masked
              type="date"
              name="birth_day"
              onValidate={this.birthDateValidate}
              placeholder={t('Edit.date_format')}
              format={this.DATE_FORMAT}
              defaultValue={form.birth_day ? form.birth_day : ''}
              onChange={this.onChangeHandler}
            />
          </div>
        </div>

        <div className="user-profile__checkbox">
          <div className="checkbox-box">
            <div
              className={clsx('checkbox-box__checkbox-container', {
                ua: isUkraine,
              })}
            >
              <input
                type="checkbox"
                id="sms"
                name="sms_subscription"
                value={!!form.sms_subscription}
                checked={!!form.sms_subscription}
                onChange={this.onChangeHandler}
              />
              <label htmlFor="sms">{t('Edit.sms_refusal')}</label>
            </div>
          </div>

          <div className="checkbox-box">
            <div
              className={clsx('checkbox-box__checkbox-container', {
                ua: isUkraine,
              })}
            >
              <input
                type="checkbox"
                id="phone"
                name="phone_subscription"
                value={!!form.phone_subscription}
                checked={!!form.phone_subscription}
                onChange={this.onChangeHandler}
              />
              <label htmlFor="phone">{t('Edit.calls_refusal')}</label>
            </div>
          </div>
        </div>
        <div className="user-profile__control">
          <Button
            onClick={() => {
              this.checkIsFutureDate(form.birth_day);
            }}
          >
            {t('Edit.save')}
          </Button>
          <Button variant="contained" onClick={this.cancelHandler}>
            {t('Edit.cancel')}
          </Button>
        </div>
      </div>
    );
  }
}

export default withTranslation()(withCheckCountries(ProfileEdit));
