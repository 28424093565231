import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { baseApi } from '@shared/api';
import { errorCodes } from './errorCodes';
import {
  ICreateOrderError,
  ICreateOrderParams,
  ICreateOrderResponse,
  IErrorCreateOrder,
} from './types';

const errorHandling = (e: FetchBaseQueryError): ICreateOrderError => {
  const {
    code,
    errors,
    data: errorData,
    message,
  } = e.data as IErrorCreateOrder;
  const errorMessage = message || 'something_went_wrong';

  const defaultError = {
    code,
    type: 'message',
    data: errorMessage,
  } as const;
  // form validation errors
  if (code === errorCodes.invalidForm) {
    if (!errorData || !errorData.errors) return defaultError;
    const {
      errors: { delivery_date },
    } = errorData || {};
    if (delivery_date?.length)
      return {
        code,
        type: 'message',
        data: 'delivery_date_changed',
      };
    return defaultError;
  }
  // products in stop list
  if (
    code === errorCodes.deliveryNotAvailable ||
    code === errorCodes.pickupNotAvailable
  ) {
    if (!errors) return defaultError;
    const { products } = errors;
    return { code, type: 'products', data: products };
  }
  // other errors
  return defaultError;
};

export const createOrderApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    createOrder: build.mutation<
      ICreateOrderResponse,
      Partial<ICreateOrderParams>
    >({
      query: (body) => ({
        url: `/cart/create`,
        method: 'POST',
        body,
      }),
      transformErrorResponse: errorHandling,
    }),
  }),
});
