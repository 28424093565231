import { ExternalImage } from '@shared/ui/image';
import PlusMinusButton from 'frontend/Components/NewCartOrder/Components/PlusMinusButton/PlusMinusButton';
import { useSelectCountryMainDomain } from '@entities/countries';
import { openCardModal } from '../../utils';
import TextBlock from '../../TextBlock/TextBlock';
import style from './Card.module.scss';
import './Card.scss';

function Card({ cartItem, currency, position }) {
  const mainDomain = useSelectCountryMainDomain();
  let {
    product: { tags = [] },
  } = cartItem;
  const {
    child = [],
    product: {
      images = [],
      title = '',
      is_action,
      is_pickup,
      is_delivery,
      product_type: { name } = {},
    } = {},
    is_action: isAction,
    product,
  } = cartItem;

  tags = tags.length ? tags.filter((tag) => tag.visible) : [];

  const price = cartItem.sum || product.price;
  const priceOrig = cartItem.sum_orig || product.price_old;
  return (
    <div
      onClick={(event) => {
        openCardModal(event, { product, cartItemId: cartItem.product_id });
      }}
      className="cart-products__card-row"
    >
      <div
        className={`img-container ${name === 'longcard' ? style.longcard : ''}`}
      >
        <ExternalImage
          mainDomain={mainDomain}
          src={`${images[0]?.filename}/80x80`}
          alt={title}
          width={80}
          height={80}
        />
      </div>
      <div className="prime-container">
        {tags.length ? (
          <div className="icons-container">
            {tags.map((tag) => (
              <i
                key={tag.tag_id}
                className={`icon-${tag.tag_id === 1 ? 'hot' : 'vegetarian'}`}
              />
            ))}
          </div>
        ) : undefined}
        <div className="text-container">
          <TextBlock
            title={title}
            child={child}
            is_action={is_action}
            is_pickup={is_pickup}
            is_delivery={is_delivery}
            productType={cartItem.product.product_type}
          />
          <div className="text-container__price">
            <strong className={is_action || isAction ? 'price-is-action' : ''}>
              {price}&nbsp;{currency}
            </strong>
            {(is_action || isAction) && priceOrig && +priceOrig !== +price ? (
              <span>
                {priceOrig}&nbsp;{currency}
              </span>
            ) : undefined}
          </div>
        </div>
        <PlusMinusButton product={product} child={child} position={position} />
      </div>
    </div>
  );
}

export default Card;
