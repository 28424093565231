import { useCallback, useEffect, useState } from 'react';
import * as session from 'modules/session';
import api from 'modules/helpers/api';
import { useTranslation } from 'react-i18next';
import { userEmitter } from 'modules/event-emitters';
import { clsx } from 'clsx';
import Emitter from 'src/frontend/Components/NewCartOrder/Emitters';
import { useQueryParams } from '@shared/lib/routing';
import { useCheckCountries, useCurrency } from '@entities/countries';
import globalStore from 'modules/global-store';
import { getProductIds } from 'modules/helpers/cart-helper';
// import { setPromocode } from '../../model/promocode-api';
import { googleAnalytics } from 'frontend/helpers/schemeGoogleAnalytics';
import { useCartPromocodeErrorModal } from '@features/modals/cart-promocode-error-modal';
import Api from '../../model/promocode-api/Api';

export function Promocode({ field }) {
  const { t } = useTranslation();
  const { promo_code } = session.get('cart');
  const [isEmpty, setIsEmpty] = useState(true);
  const [isVisibility, setIsVisibility] = useState(false);
  const [value, setValue] = useState(promo_code || '');
  const [isApplied, setIsApplied] = useState(Boolean(promo_code));
  const { isMontenegro } = useCheckCountries();
  const currency = useCurrency();
  const { get, remove } = useQueryParams();
  const errorModal = useCartPromocodeErrorModal();

  const {
    name,
    type,
    required,
    className,
    fieldsetClassName,
    invalid,
    maxLength,
    autoComplete,
  } = field;

  const setPromo = useCallback(
    async (promoValue) => {
      const upperCaseValue = promoValue.toUpperCase();
      const isReset = !upperCaseValue;

      try {
        const cart = await Api.setPromocode(upperCaseValue, null);
        const { promo_code, promo_applied, promo_code_bonuses } = cart;
        const ids = getProductIds(cart);

        try {
          const products = await api('product.get', { id: ids });
          globalStore.set('cart_products', products);
        } catch (error) {
          console.log(error);
        }

        // Если применен или сброшен успешно
        if (
          (!promo_code && !promo_code_bonuses) ||
          (promo_code && promo_applied)
        ) {
          Emitter.emit('MODAL_CALL', {
            modal: 'PromocodeCartModal',
          });
          googleAnalytics.promocodeYesDesire();
          if (isReset) {
            setValue('');
            setIsVisibility(false);
            setIsApplied(false);
            return;
          }
          setIsApplied(true);
          return;
        }

        // Иначе показываем модалку с ошибкой
        const { message, min_summ_order, remark } = promo_code_bonuses;

        errorModal.show({ message, min_summ_order, remark, currency });
        googleAnalytics.promocodeNoDesire();
        setValue('');
        setIsEmpty(true);
        setIsApplied(false);
      } catch (error) {
        const { message = '', status = '' } = error || {};
        console.log(status);
        errorModal.show({ message });
        setValue('');
        setIsEmpty(true);
        setIsApplied(false);
      }
    },
    [currency, errorModal]
  );

  const setBonusState = (checkedValue) => {
    const isFilled = checkedValue.length >= 3;
    setIsEmpty(!isFilled);
  };

  const updateCartBonus = () => {
    api('cart.refresh')
      .then(({ cart = {} }) => {
        session.set('cart', cart);
        Emitter.emit('CART_CHANGE');
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    setBonusState(value);
    updateCartBonus();
    userEmitter.addListener('User.Login.Event', () => updateCartBonus);

    return () => {
      userEmitter.addListener('User.Login.Event', () => updateCartBonus);
    };
  }, [value]);

  useEffect(() => {
    const cart = session.get('cart', {});

    const isNewPromoCode =
      cart.promo_code && cart.promo_code.toUpperCase() !== promo_code;

    if (promo_code && isNewPromoCode) {
      setPromo(value);
    }
  }, [promo_code, setPromo, value]);

  useEffect(() => {
    const setPromoBySearchParams = async (newPromo) => {
      await setPromo(newPromo);
    };
    const promocodeByQueryParams = get('userProfilePromo');
    if (promocodeByQueryParams) {
      setPromoBySearchParams(promocodeByQueryParams);
      remove('userProfilePromo');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getPromocode = () => {
    if (!isEmpty) setPromo(value);
  };

  const resetPromocode = () => {
    setPromo('');
  };

  const submit = () => {
    return isApplied ? resetPromocode() : getPromocode();
  };

  const handleChange = (e) => {
    const newValue = e.target.value;
    setValue(newValue);
    if (e.key === 'Enter') {
      submit(e);
    } else {
      setBonusState(newValue);
    }
  };

  const prevent = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const fieldSetClass = clsx('input-row ', ` ${fieldsetClassName}`, {
    ' invalid': invalid,
    ' hidden-input': !isVisibility && !isApplied,
    ' bonus': isApplied,
    ' mne': isMontenegro,
  });
  return (
    <fieldset className={fieldSetClass}>
      <div className="input-row__container">
        <input
          id={`field-${name}`}
          type={type}
          required={required}
          name={name}
          placeholder={t('locale.promocode')}
          className={className || ''}
          maxLength={maxLength}
          disabled={isApplied}
          autoComplete={autoComplete}
          value={value}
          onKeyDown={(e) => (e.key === 'Enter' ? prevent(e) : undefined)}
          onKeyUp={(e) => handleChange(e)}
          onChange={(e) => handleChange(e)}
        />
        {(isApplied || !isEmpty) && (
          <button type="button" className="input-btn" onClick={() => submit()}>
            {isApplied ? t('NewCart.cancel') : t('NewCart.apply_promocode')}
          </button>
        )}
      </div>
    </fieldset>
  );
}
