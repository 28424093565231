import { stringToDate, isBefore, isAfter } from '@shared/lib/date';
import { scheme, Type } from '../model/scheme';

export const holidayCheck = ({
  countryId,
  type,
}: {
  countryId: number;
  type: keyof typeof Type;
}) => {
  const currentDate = new Date();
  const holiday = scheme.find(({ start, end, countries, types }) => {
    const isTimeSpan =
      isAfter(currentDate, stringToDate(start)) &&
      isBefore(currentDate, stringToDate(end)); // not include

    const isCelebrationCountry = countries.includes(countryId);

    const currentType = types.some((t) => t === type);

    return isTimeSpan && isCelebrationCountry && currentType;
  });

  return holiday?.name;
};
