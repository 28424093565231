import './desktop/Main.scss';
import { useEffect, useState } from 'react';
import globalStore from 'modules/global-store';
import api from 'modules/helpers/api';
import { isEmpty } from 'lodash';
import { Media } from '@shared/lib/media';
import { useCityAndLang } from '@shared/lib/use-city-and-lang';
import MainPageDesktop from './desktop/Main';
import MainPageMobile from './mobile/MainMobile';

function MainPage() {
  const { lang, city } = useCityAndLang();
  const [mainpage, setMainPage] = useState([]);
  const [promoLinks, setPromoLinks] = useState();
  const [banner, setBanner] = useState([]);
  const [stock, setStock] = useState([]);
  const [news, setNews] = useState([]);
  const [articles, setArticles] = useState([]);
  const [contest, setContest] = useState([]);
  const params = {
    lang,
    city,
  };
  const initialData = async (fetch) => {
    try {
      const [mainpage, promoLinks, banner, stock, news, articles, contest] =
        await Promise.all([
          fetch('settings.mainpage'),
          fetch('/endpoint/setting/promo_links', {}, params),
          fetch('/endpoint/banner/slider', {}, params),
          fetch('stock.main'),
          fetch('news.main'),
          fetch('articles.main'),
          fetch('contest.main'),
        ]);
      setMainPage(mainpage);
      setPromoLinks(promoLinks.data.promo_links);
      setBanner(banner.data.slider);
      setStock(stock);
      setNews(news);
      setArticles(articles);
      setContest(contest);
    } catch (e) {
      console.error('Main', e);
    }
  };

  const loadedKey = 'main_loaded';

  useEffect(() => {
    if (globalStore.get(loadedKey)) {
      globalStore.unlink(loadedKey);
    } else {
      initialData(api).catch((e) => {
        console.error(e);
      });
    }
  }, []);

  const isMainPageEmpty = isEmpty(mainpage);

  if (isMainPageEmpty) return null;

  return (
    <>
      <Media lessThan="notebook">
        <MainPageMobile
          mainpage={mainpage}
          promoLinks={promoLinks}
          banner={banner}
          stocks={stock}
          news={news}
          articles={articles}
          contest={contest}
        />
      </Media>
      <Media greaterThanOrEqual="notebook">
        <MainPageDesktop
          mainpage={mainpage}
          promoLinks={promoLinks}
          banner={banner}
          stocks={stock}
          news={news}
          articles={articles}
          contest={contest}
        />
      </Media>
    </>
  );
}

export { MainPage };
