import { useTranslation } from 'react-i18next';
import { useCityTextIdParam } from './routing/city-text-id-param';

export const useCityAndLang = () => {
  const { i18n } = useTranslation();
  const lang = i18n.language;
  const city = useCityTextIdParam();

  return { city, lang };
};
