import './SocialShare.scss';
import { clsx } from 'clsx';
import { Component } from 'react';
import {
  FacebookShareButton,
  TwitterShareButton,
  VKShareButton,
  OKShareButton,
} from 'react-share';

import { withTranslation } from 'react-i18next';
import { isAbsoluteUrl } from '@shared/lib';
import Icon from '../../Elements/Icon/Icon';
import globalStore from '../../../../modules/global-store';

class SocialShare extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAction: false,
    };
  }

  action = (event) => {
    event.preventDefault();

    const { isAction } = this.state;

    this.setState({
      isAction: !isAction,
    });
  };

  getAbsoluteUrl = (url) => {
    if (typeof window !== 'undefined') {
      const {
        location: { protocol },
      } = window;
      const {
        location: { hostname },
      } = window;

      url = url || window.location.pathname;

      if (isAbsoluteUrl(url)) {
        return url;
      }
      return `${protocol}//${hostname}${url}`;
    }
  };

  render() {
    const { isAction } = this.state;
    const {
      link: shareUrl,
      title,
      image: exampleImage,
      description,
      t,
    } = this.props;
    const className = clsx('social-share', {
      'social-share--active': isAction,
    });
    const { text_id } = globalStore.get('current_city');
    const showShareElem = text_id !== 'kiev';

    const url = this.getAbsoluteUrl(shareUrl);
    const image = this.getAbsoluteUrl(exampleImage);
    return (
      <div className={className}>
        <Icon w="62" h="62" icon="social-share" onClick={this.action} />
        {isAction ? (
          <div
            className={`social-share__panel ${!showShareElem ? 'offset' : ''}`}
            onMouseLeave={() => this.setState({ isAction: null })}
          >
            <div className="social-share__title">
              {t('SocialShare.select_share_network')}:
            </div>

            <div className="social-share__items">
              {showShareElem && (
                <div className="social-share__item">
                  <VKShareButton
                    url={url}
                    image={image}
                    title={title}
                    description={description}
                    windowWidth={660}
                    windowHeight={460}
                    className="social-share__item__share-button"
                  >
                    <Icon w="56" h="56" icon="vk" />
                  </VKShareButton>
                </div>
              )}
              {showShareElem && (
                <div className="social-share__item">
                  <OKShareButton
                    url={url}
                    image={image}
                    title={title}
                    description={description}
                    windowWidth={660}
                    windowHeight={460}
                    className="social-share__item__share-button"
                  >
                    <Icon w="56" h="56" icon="ok" />
                  </OKShareButton>
                </div>
              )}
              <div className="social-share__item">
                <FacebookShareButton
                  url={url}
                  quote={title}
                  // picture={image}
                  // title={title}
                  // description={description}
                  windowWidth={660}
                  windowHeight={460}
                  className="social-share__item__share-button"
                >
                  <Icon w="56" h="56" icon="fb" />
                </FacebookShareButton>
              </div>
              <div className="social-share__item">
                <TwitterShareButton
                  url={url}
                  title={title}
                  windowWidth={660}
                  windowHeight={460}
                  hashtags={['sushiwok']}
                  className="social-share__item__share-button"
                >
                  <Icon w="56" h="56" icon="tw" />
                </TwitterShareButton>
              </div>
            </div>
          </div>
        ) : undefined}
      </div>
    );
  }
}

export default withTranslation()(SocialShare);
