import './Attributes.scss';
import { Component } from 'react';
import { cardsEmitter, filterPanelEmitter } from 'modules/event-emitters';
import { withTranslation } from 'react-i18next';
import FilterCheckbox from '../Components/Checkbox/FilterCheckbox/FilterCheckbox';

class Attributes extends Component {
  constructor(props) {
    super(props);
    const {
      attributes: { is_action = false, is_new = false },
    } = props;
    this.state = {
      actionChecked: is_action,
      newsChecked: is_new,
    };
  }

  componentDidMount() {
    filterPanelEmitter.addListener('reset_all', this.reset);
  }

  componentWillUnmount() {
    filterPanelEmitter.removeListener('reset_all', this.reset);
  }

  onToggleNews = ({ checked }) => {
    this.setState({ newsChecked: checked });
    cardsEmitter.emit('ProductList.sortAttributes', {
      value: 'is_new',
      checked,
    });
  };

  onToggleAction = ({ checked }) => {
    this.setState({ actionChecked: checked });
    cardsEmitter.emit('ProductList.sortAttributes', {
      value: 'is_action',
      checked,
    });
  };

  reset = () => {
    this.setState({
      actionChecked: false,
      newsChecked: false,
    });
    cardsEmitter.emit('ProductList.sortAttributes', {
      value: 'is_new',
      checked: false,
    });
    cardsEmitter.emit('ProductList.sortAttributes', {
      value: 'is_action',
      checked: false,
    });
  };

  render() {
    const { newsChecked, actionChecked } = this.state;
    const { t } = this.props;
    return (
      <div className="attributes">
        <FilterCheckbox
          name="News"
          title={t('Attributes.News')}
          checked={newsChecked}
          onToggle={this.onToggleNews}
        />
        <FilterCheckbox
          name="Action"
          title={t('Attributes.Action')}
          checked={actionChecked}
          onToggle={this.onToggleAction}
        />
      </div>
    );
  }
}

export default withTranslation()(Attributes);
