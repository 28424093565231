import { Component } from 'react';
import globalStore from 'modules/global-store';
import * as session from 'modules/session';
import ssr from 'modules/decorators/ssr';
import api from 'modules/helpers/api';
import { LinkButton } from '@shared/ui/button';
import { Breadcrumbs } from '@shared/ui/breadcrumbs';
import { PageTitle } from '@shared/ui/titles';
import { PageContainer } from '@shared/ui/content-containers';
import { Banner } from '@widgets/seoPage';
import StockProducts from './StockProducts';
import ErrorComponent from '../../Components/ErrorComponent/ErrorComponent';
import CustomText from '../../Components/CustomText/CustomText';
import CustomHelmet from '../../Components/CustomHelmet';
import Description from './Description';
import styles from './stock.module.scss';

class Stock extends Component {
  constructor(props) {
    super(props);
    const stock = globalStore.get('stock', false);
    const stockProducts = globalStore.get('stock_products', []);
    this.state = {
      stock,
      notFound: false,
      stockProducts,
    };
  }

  componentDidMount() {
    const { stock } = this.state;
    if (globalStore.get(Stock.LOADED_KEY)) {
      globalStore.unlink(Stock.LOADED_KEY);
      this.setState({
        notFound: !stock,
      });
    } else {
      Stock.initialData(api, this.props.match.params, globalStore).then(() => {
        const stock = globalStore.get('stock', false);
        const stockProducts = globalStore.get('stock_products', []);
        this.setState({
          stock,
          notFound: !stock,
          stockProducts,
        });
      });
    }
    if (globalStore.get('stock', false)) {
      globalStore.put('is404', undefined, true);
    }
  }

  componentWillUnmount() {
    globalStore.set('stock', null);
  }

  static LOADED_KEY = 'stock_loaded';

  static initialData = async (fetch, params = {}, globalStore) => {
    const { stock: text_id } = params;
    try {
      const result = await fetch('stock.get', { text_id });
      if (!result || !Array.isArray(result) || !result[0]) {
        globalStore.set('is404', true);
        globalStore.set('stock', false);
        return false;
      }
      globalStore.set('stock', result[0]);
      const { pinned_products: stockProducts = [] } = result[0];

      globalStore.set('stock_products', stockProducts);
    } catch (error) {
      console.error('Stock', error);
      return {};
    }
  };

  render() {
    const { stock, notFound, stockProducts = [] } = this.state;
    const {
      t,
      match: {
        params: { article: text_id },
      },
    } = this.props;

    if (notFound) {
      return (
        <ErrorComponent
          action_link="/akcii"
          action_text={t('Stock.show_all')}
        />
      );
    }

    const {
      title = '',
      description,
      description_stock,
      images = [],
    } = stock || {};
    const isLoad = !!Object.keys(stock).length;

    const links = [
      {
        href: '/akcii',
        text: t('localization.Stocks'),
      },
      {
        text: title,
      },
    ];
    return (
      <>
        <Banner pathname={`/akcii/${text_id}`} />
        <CustomHelmet
          title={title}
          type="article"
          image={`${session.get('protocol')}://${session.get('location')}/img/${
            images[0] && images[0].filename
          }`}
          ogTitle={title}
          ogDescription={stock.description_stock}
        />
        <PageContainer>
          <Breadcrumbs links={links} />
          <PageTitle isCenterOnMobile>{title}</PageTitle>
          <div className={styles.description}>
            <Description description={description || description_stock} />
          </div>
          {stockProducts && stockProducts.length && isLoad ? (
            <StockProducts products={stockProducts} position="promotion" />
          ) : null}
          {isLoad && (
            <div className={styles.linkBtnContainer}>
              <LinkButton href="/akcii">{t('Stock.show_all')}</LinkButton>
            </div>
          )}

          <CustomText className={styles.customTextWrap} />
        </PageContainer>
      </>
    );
  }
}

export default ssr(Stock);
