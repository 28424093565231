import { ISeoPage } from '@widgets/seoPage';
import type { FetchLoaderType } from '@shared/lib/routing';
import { IPage } from './model';

type Params = {
  city: string;
  pageId: string;
};
export const StaticPageLoader = async (
  fetch: FetchLoaderType,
  { params, lang }: { params: Params; lang: string }
) => {
  const { city, pageId } = params;

  const [seoPageResponse, staticPageResponse] = await Promise.all([
    fetch<ISeoPage>({
      url: '/seo/page',
      params: { url: `/page/${pageId}`, city, lang },
    }),
    fetch<{ page: IPage[] }>({
      url: '/static_page/index',
      params: { text_id: pageId, city, lang },
    }),
  ]);
  return {
    seoPageData: seoPageResponse.data,
    staticPageData: staticPageResponse.data,
  };
};
