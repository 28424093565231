import { clsx } from 'clsx';
import { useState } from 'react';
import styles from './controlButton.module.scss';
import { DURATION_TIME } from '../../model/constants';

interface IControllButtonProps {
  direction: 'left' | 'right';
  onClick?: () => void;
  updateSlideIndex: () => void;
}

export function ControlButton({
  direction,
  onClick,
  updateSlideIndex,
}: IControllButtonProps) {
  const [disabled, setDisabled] = useState(false);

  const handleClick = () => {
    setDisabled(true);
    setTimeout(() => {
      setDisabled(false);
    }, DURATION_TIME);
    updateSlideIndex();
    if (onClick) onClick();
  };

  return (
    <button
      disabled={disabled}
      onClick={handleClick}
      type="button"
      aria-label={direction === 'left' ? 'предыдущий' : 'следующий'}
      className={clsx(styles.root, {
        [styles.left]: direction === 'left',
        [styles.right]: direction === 'right',
      })}
    />
  );
}
