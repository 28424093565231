import { DetailedHTMLProps, ImgHTMLAttributes } from 'react';
import NoPhoto from './assets/no_photo.svg?url';

interface IInternalImage
  extends DetailedHTMLProps<
    ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  > {
  src: string;
  width: number;
  height: number;
  isCenter?: boolean;
}
export function InternalImage({
  src,
  width,
  height,
  isCenter = false,
  alt = '',
  loading = 'lazy',

  ...props
}: IInternalImage) {
  return (
    <img
      src={src}
      width={width}
      height={height}
      alt={alt}
      loading={loading}
      style={isCenter ? { margin: '0 auto' } : {}}
      onError={(e) => {
        e.currentTarget.src = NoPhoto;
      }}
      {...props}
    />
  );
}
