import { useTranslation } from 'react-i18next';
import { useCurrency } from '@entities/countries';
import style from './minAmountMessage.module.scss';

function MinAmountMessage({
  orderMin,
  total,
}: {
  orderMin: number;
  total: number;
}) {
  const { t } = useTranslation();
  const currency = useCurrency();
  if (total >= orderMin) return null;

  return (
    <p className={style.message}>
      {t('NewCart.cart_warning')}&nbsp;
      {orderMin}&nbsp;{currency}
    </p>
  );
}

export default MinAmountMessage;
