import { useModal } from '@ebay/nice-modal-react';
import { modalIds } from '@features/modals/modal-ids';
import type { selectCityModalParams } from './types';

export const useSelectCityModal = () => {
  const modal = useModal(modalIds.SelectCityModalId);

  return {
    ...modal,
    show: (args: selectCityModalParams) => modal.show(args),
  };
};
