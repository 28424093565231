import { TitleWithLines } from '@shared/ui/titles';
import { SmartLink, Carousel } from '@shared/ui';
import { Media } from '@shared/lib/media';
import { clsx } from 'clsx';
import Item from '../item/item';
import styles from './list.module.scss';
import type { IPublication } from '../../model/types';

interface IControl {
  direction: 'left' | 'right';
  onClick?: () => void;
}

function Control({ direction, onClick }: IControl) {
  return (
    <button
      onClick={onClick}
      type="button"
      aria-label={direction === 'left' ? 'предыдущий' : 'следующий'}
      className={clsx(styles.arrow, {
        [styles.left]: direction === 'left',
        [styles.right]: direction === 'right',
      })}
    />
  );
}

export function PublicationList({
  list,
  href,
  titleText,
  bottomLinkText,
}: {
  list?: IPublication[];
  href: string;
  titleText: string;
  bottomLinkText: string;
}) {
  if (!list?.length) return null;

  return (
    <div className="main-container">
      <div className="main-container-inner">
        <section className={clsx(styles.root)}>
          <TitleWithLines>
            <SmartLink className={styles.titleLink} href={href}>
              {titleText}
            </SmartLink>
          </TitleWithLines>
          <Media greaterThanOrEqual="notebook">
            <ul className={styles.desktopList}>
              {list.slice(0, 3).map((item) => {
                return (
                  <li className={styles.desktopItem} key={item.id}>
                    <Item item={item} rootHref={href} />
                  </li>
                );
              })}
            </ul>
          </Media>
          <Media lessThan="notebook">
            <Carousel
              customLeftArrow={<Control direction="left" />}
              customRightArrow={<Control direction="right" />}
              itemClass={styles.carouselItem}
              responsive={{
                mobile: { breakpoint: { max: 430, min: 0 }, items: 1 },
                tablet: { breakpoint: { max: 768, min: 431 }, items: 2 },
                laptop: { breakpoint: { max: 1024, min: 769 }, items: 3 },
              }}
              deviceType="tablet"
              ssr
            >
              {list.map((item) => {
                return <Item key={item.id} item={item} rootHref={href} />;
              })}
            </Carousel>
          </Media>
          <div className={styles.linkContainer}>
            <SmartLink className={styles.link} href={href}>
              {bottomLinkText}
            </SmartLink>
          </div>
        </section>
      </div>
    </div>
  );
}
