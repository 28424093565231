import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import { Schemaorg, ISchemaorg } from './schemaorg';

function PathHelmet({ host }: { host: string }) {
  const { pathname } = useLocation();
  const currentPath = new URL(pathname, host).toString();

  return (
    <Helmet>
      <meta property="og:url" content={currentPath} />
      <link rel="canonical" href={currentPath} />
    </Helmet>
  );
}
interface IAppHelmet extends Omit<ISchemaorg, 'host'> {
  mainDomain: string;
  formatLocale: string;
}

export function AppHelmet({
  mainDomain,
  formatLocale,
  ...shemeorgProps
}: IAppHelmet) {
  const host = `https://${mainDomain}`;

  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="format-detection" content="telephone=no" />
        <meta property="og:type" content="website" />
        <meta content={formatLocale} property="og:locale" />
      </Helmet>
      <PathHelmet host={host} />
      <Schemaorg {...shemeorgProps} host={host} />
    </>
  );
}
