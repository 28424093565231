import { Helmet } from 'react-helmet-async';
import { RuLogoUrl } from '@widgets/header';

export interface ISchemaorg {
  host: string;
  countryName: string;
  cityName: string;
  cityTextId: string;
  cityPhone: string;
  worktimeFrom: string;
  worktimeTo: string;
}
export function Schemaorg({
  host,
  countryName,
  cityName,
  cityTextId,
  cityPhone,
  worktimeTo,
  worktimeFrom,
}: ISchemaorg) {
  const cityUrl = `${host}/${cityTextId}`;
  const seoScript = {
    '@context': 'https://schema.org',
    '@type': 'FastFoodRestaurant',
    url: cityUrl,
    image: `${host}${RuLogoUrl}`, // лого
    name: 'Суши Wok',
    acceptsReservations: 'no',
    menu: `${cityUrl}/menu`, // меню города
    servesCuisine: 'японская, паназиатская, итальянская, китайская, смешанная',
    currenciesAccepted: 'Рубли',
    openingHours: `Mo-Su ${worktimeFrom}-${worktimeTo}`, // дни/часы
    paymentAccepted: 'наличные, карты',
    priceRange: '150-1000',
    address: {
      '@type': 'PostalAddress',
      addressCountry: countryName, // страна
      addressLocality: cityName, // город
      telephone: cityPhone.replace(/[^0-9]/g, ''), // телефон
      alternateName: 'Sushi Wok',
      description: 'Самовывоз и доставка суши, роллов, лапша Wok, pizza',
    },
    areaServed: [`${cityUrl}/delivery`],
    serviceArea: `${cityUrl}/delivery`,
    foundingDate: '2011',
    logo: {
      '@type': 'ImageObject',
      caption: 'Суши Wok',
      contentUrl: `${host}${RuLogoUrl}`,
    },
  };
  return (
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(seoScript)}</script>
    </Helmet>
  );
}
