import { useParams } from 'react-router-dom';
import SmileyImage from 'assets/images/icon/smiley.png';
import orderDoneImage from 'assets/images/icon/order-done.svg?url';
import { useTranslation } from 'react-i18next';
import { useGetOrderQuery } from '@entities/order';
import { SmartLink } from '@shared/ui';
import { PageContainer } from '@shared/ui/content-containers';
import { Banner } from '@widgets/seoPage';
import style from './OrderFeedback.module.scss';
import ErrorComponent from '../../../Components/ErrorComponent/ErrorComponent';
import CustomHelmet from '../../../Components/CustomHelmet';

type Params = {
  city: string;
  order_hash: string;
  order_salt: string;
};
function OrderFeedback() {
  const { i18n } = useTranslation();
  const { order_hash, order_salt, city } = useParams<Params>();
  const { data, error, isFetching } = useGetOrderQuery({
    order_hash,
    order_salt,
    city,
    lang: i18n.language,
  });

  if (isFetching) {
    return null;
  }
  if (error) {
    return <ErrorComponent />;
  }
  if (!data?.data?.order) {
    return null;
  }
  const { order } = data.data;
  const { courier_tips } = order;
  return (
    <>
      <Banner pathname={`/order/feedback/${order_hash}/${order_salt}`} />
      <CustomHelmet title="Обратная связь" noindex />
      <PageContainer>
        <div className={style.orderQuality}>
          <h1>Та-дам!</h1>
          <div className={style.smileText}>
            <span>Мы доставили твой заказ и желаем приятного аппетита</span>{' '}
            <img src={SmileyImage} alt="smiley" />
          </div>
          <div className={style.imageContainer}>
            <img src={orderDoneImage} alt="order-done" />
          </div>
          <div className={style.btnContainer}>
            <span>Всегда рады обратной связи</span>
            <SmartLink
              href={`/reviews?order=${order.hash}`}
              className={style.reviewBtn}
            >
              Оставить отзыв о заказе
            </SmartLink>
          </div>
          {courier_tips ? (
            <div className={style.btnContainer}>
              <span>
                Понравилась доставка?
                <br />
                Оставь чаевые
              </span>
              <a className={style.tipBtn} href={`//${courier_tips}`}>
                Поблагодарить курьера
              </a>
            </div>
          ) : null}
        </div>
      </PageContainer>
    </>
  );
}

export default OrderFeedback;
