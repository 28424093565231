import { useModal } from '@ebay/nice-modal-react';
import { modalIds } from '@features/modals/modal-ids';
import type { ICartPromocodeErrorModal } from '../ui/CartPromocodeErrorModal';

export const useCartPromocodeErrorModal = () => {
  const modal = useModal(modalIds.cartPromocodeErrorModalId);

  return {
    ...modal,
    show: (args: ICartPromocodeErrorModal) => modal.show(args),
  };
};
