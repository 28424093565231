import { Component } from 'react';
import store from 'modules/global-store';
import * as session from 'modules/session';
import menuHelper from 'modules/helpers/menu-helper';
import productHelper from 'modules/helpers/product-helper';
import api from 'modules/helpers/api';
import ssr from 'modules/decorators/ssr';
import { analytics } from 'frontend/analytics/analytics';
import { getFbPixelDataToViewCategory } from 'frontend/analytics/analyticsHelper';
import { Media } from '@shared/lib/media';
import { Breadcrumbs } from '@shared/ui/breadcrumbs';
import { PageContainer } from '@shared/ui/content-containers';
import { PageTitle } from '@shared/ui/titles';
import { Preloader } from '@shared/ui/preloader';
import { Banner } from '@widgets/seoPage';
import styles from './styles.module.scss';
import ErrorComponent from '../../Components/ErrorComponent/ErrorComponent';
import CardWokMobile from '../../Components/Products/CardWok/CardWokMobile';
import CardWok from '../../Components/Products/CardWok/CardWok';
import CardList from '../../Components/Products/CardList/CardList';
import CustomText from '../../Components/CustomText/CustomText';
import CustomHelmet from '../../Components/CustomHelmet';

class ProductList extends Component {
  static LOADED_KEY = 'products_loaded';

  static initialData(fetch, params, store) {
    const SOUP_CATEGORY_ID = 713;
    const WOK_CATEGORY_ID = 709;
    const { menu, skipFetchProducts = false } = params;
    const categories = store.get('menu', []);
    const category = categories.find((item) => item.text_id === menu);

    if (!category) {
      store.get('is404', true);
      return;
    }

    if (skipFetchProducts) {
      return;
    }

    if (!category.products) {
      category.products = [];
    }

    const { id } = category;
    let storedProducts = store.get('products');
    if (!storedProducts) {
      store.set('products', {});
      storedProducts = store.get('products');
    }

    return Promise.all([
      fetch('product.list', { menu })
        .then((products) => {
          for (const product of products) {
            category.products.push(product.id);
            storedProducts[product.id] = product;
          }
          return products;
        })
        .then((products) => {
          if (+id === SOUP_CATEGORY_ID) {
            const soupHasToppings = (soup) =>
              soup.type === 'soup_boullion' ||
              (soup.product_type || {}).name === 'soup';
            const soupToppingsIds =
              productHelper.getToppingsIdFromProducts(
                products.filter(soupHasToppings)
              ) || [];

            if (soupToppingsIds.length) {
              return fetch('product.get', { id: soupToppingsIds }).then(
                (soupToppings) => store.set('soup_toppings', soupToppings)
              );
            }
          } else if (+id === WOK_CATEGORY_ID) {
            return fetch('product.group_wok')
              .then((groupWoks) => {
                store.set('group_wok', groupWoks);
                const allToppings =
                  productHelper.getToppingsIdFromProducts(
                    products.filter(
                      (wok) => (wok.product_type || {}).name === 'wok'
                    )
                  ) || [];

                for (const { toppings: itemToppings = [] } of groupWoks) {
                  for (const id of itemToppings) {
                    if (id in allToppings) {
                      continue;
                    }
                    allToppings.push(id);
                  }
                }
                return allToppings;
              })
              .then((allToppings) => {
                if (allToppings.length) {
                  return fetch('product.get', { id: allToppings }).then(
                    (wokToppings) => store.set('wok_toppings', wokToppings)
                  );
                }
              });
          }
        })
        .catch((e) => {
          console.error('ProductList 1', e);
          category.error = e;
        }),
      fetch('stock.catalog')
        .then((stock_catalog) => store.set('stock_catalog', stock_catalog))
        .catch((e) => console.error('ProductList 2', e)),
    ]);
  }

  constructor(props) {
    super(props);
    this.state = {
      stock_catalog: store.get('stock_catalog', []),
    };
  }

  componentDidMount() {
    if (store.get(ProductList.LOADED_KEY)) {
      store.unlink(ProductList.LOADED_KEY);
    } else {
      const { params: { menu } = {} } = this.props.match;

      const products = menuHelper
        .getProducts(menu)
        .filter((item) => !item.hide)
        .sort((a, b) => {
          return b.position - a.position;
        });
      const filteredProducts = this.getFilteredCardListByTag(products);

      analytics.fbPixel(
        'ViewCategory',
        getFbPixelDataToViewCategory(filteredProducts)
      );

      const { stock_catalog } = this.state;
      if (!stock_catalog || !stock_catalog.length) {
        api('stock.catalog')
          .then((stock_catalog) => {
            store.set('stock_catalog', stock_catalog);
            this.setState(stock_catalog);
          })
          .catch((e) => console.error('ProductList 2', e));
      }
      ProductList.initialData(api, { menu, skipFetchProducts: true }, store);
    }
  }

  componentDidUpdate(prevProps) {
    const { params: { menu } = {} } = this.props.match;
    const { params: { menu: prevMenu } = {} } = prevProps.match;
    if (menu !== prevMenu) {
      ProductList.initialData(api, { menu, skipFetchProducts: true }, store);
    }
  }

  getFilteredCardListByTag(products) {
    const params = new URLSearchParams(this.props.location.search);
    if (!params.get('tag_id')) {
      return products;
    }

    const currentTags = params.get('tag_id').split(',');
    return products.filter((product) => {
      if (!product.tags[0]) {
        return false;
      }
      return params.get('every') === 'true'
        ? currentTags.every(function (tag) {
            for (let i = 0; i < product.tags.length; i += 1) {
              if (product.tags[i].tag_id === tag) {
                return true;
              }
            }
            return false;
          })
        : product.tags.some(function (tagInProduct) {
            for (let i = 0; i < currentTags.length; i += 1) {
              if (tagInProduct.tag_id === currentTags[i]) {
                return true;
              }
            }
            return false;
          });
    });
  }

  render() {
    const {
      match: { params },
      t,
    } = this.props;
    const toppings = productHelper.getToppingsIdFromProducts(
      menuHelper.getProducts(params.menu)
    );

    const products = menuHelper
      .getProducts(params.menu)
      .filter((product) => !toppings.includes(product.id)) // чистим коллекцию от продуктов если они уже включены в суперпродукт
      .filter((item) => !item.hide)
      .sort((a, b) => {
        return b.position - a.position;
      });
    const cart = session.get('cart');
    const menu = menuHelper.get(params.menu);
    if (!menu || !products || (!menu.visible && !menu.available_at_link)) {
      return <ErrorComponent />;
    }

    if (Array.isArray(products) && !products.length) {
      return <Preloader />;
    }

    const { title } = menu;
    const filteredProducts = this.getFilteredCardListByTag(products);
    let image = '';
    if (menu.images_desktop && menu.images_desktop[0]) {
      image = `${session.get('protocol')}://${session.get('location')}/img/${
        menu.images_desktop[0].filename
      }`;
    }
    return (
      <>
        <Banner pathname={`/menu/${menu.text_id}`} />
        <CustomHelmet title={title} image={image} ogTitle={title} />
        <PageContainer>
          <Media greaterThanOrEqual="notebook" className={styles.header}>
            <PageTitle>{title}</PageTitle>
            <Breadcrumbs
              links={[
                {
                  href: '/menu',
                  text: t('ProductList.Menu'),
                },
                {
                  text: title,
                },
              ]}
            />
          </Media>

          {menu.type === 'wok' ? (
            <>
              <Media
                className={styles.desktopWokContainer}
                greaterThanOrEqual="notebook"
              >
                <CardWok
                  {...{ params, t, title }}
                  products={products}
                  group={store.get('group_wok', [])}
                  cart={cart}
                />
              </Media>
              <Media lessThan="notebook">
                <CardWokMobile
                  {...{ params, t, title }}
                  products={products}
                  group={store.get('group_wok', [])}
                  cart={cart}
                />
              </Media>
            </>
          ) : (
            <CardList
              title={title}
              {...params}
              menu={menu}
              products={filteredProducts}
              cart={cart}
              position="catalog"
            />
          )}
          <CustomText />
        </PageContainer>
      </>
    );
  }
}

export default ssr(ProductList);
