import { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { modalEmitter, orderEmitter } from 'modules/event-emitters';
import { Button } from '@shared/ui/button';
import { useTranslation } from 'react-i18next';
import { useSelectCountryMainDomain } from '@entities/countries';
import { ActiveOrder, getProductsIds } from '@entities/user';
import { useCityAndLang } from '@shared/lib/use-city-and-lang';
import { getProductMenuUrl, useGetProductQuery } from '@entities/product';
import { useSelectMenu } from '@entities/menu';
import { CloseButton } from '@shared/ui/close-button';
import modernModal from '../../ModernModal';
import styles from './reorderModal.module.scss';

const customStyles = {
  overlay: {
    zIndex: 20,
    backgroundColor: 'rgba(252, 252, 252, 0.7)',
    overflowY: 'auto',
  },
  content: {
    maxWidth: '790px',
    top: '20%',
    left: '0',
    right: '0',
    bottom: 'auto',
    margin: 'auto',
    overflow: 'visible',
    padding: '30px',
    border: 'none',
    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 16px 60px 20px',
    boxSizing: 'border-box',
    marginBottom: '50px',
  },
};

export function ReorderModal() {
  const { t } = useTranslation();
  const mainDomain = useSelectCountryMainDomain();
  const { city, lang } = useCityAndLang();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [order, setOrder] = useState(null);
  const menu = useSelectMenu();

  const modalShow = (order) => {
    modernModal.openModal();
    setOrder(order);
    setModalIsOpen(true);
  };

  const modalClose = () => {
    modernModal.closeModal();
    setOrder(null);
    setModalIsOpen(false);
  };

  useEffect(() => {
    Modal.setAppElement('body');
    modalEmitter.addListener('Reorder.Modal.Show', modalShow);
    modalEmitter.addListener('Reorder.Modal.Close', modalClose);

    return () => {
      modalEmitter.removeListener('Reorder.Modal.Show', modalShow);
      modalEmitter.removeListener('Reorder.Modal.Close', modalClose);
    };
  });

  const prepareCart = () => {
    return {
      ...order,
      ...order.items.filter((item) => item.price !== 0 && item.price !== null),
    };
  };

  const reorderAdd = (e) => {
    e.preventDefault();
    orderEmitter.emit('Order.Reorder.Add', prepareCart());
  };

  const reorderNew = (e) => {
    e.preventDefault();
    orderEmitter.emit('Order.Reorder.New', prepareCart());
  };

  const items = order?.items || [];
  const productsIds = getProductsIds(items);

  const { data } = useGetProductQuery(
    { city, lang, id: productsIds },
    { skip: !productsIds.length }
  );

  const products = data?.data?.product || [];
  const itemsWithUrl = items.map((item) => {
    const product = products.find(
      (itemProduct) => itemProduct.id === item.product_id
    );
    const productMenu = menu.find(
      (itemMenu) => itemMenu.id === product?.menu_id
    );
    const productUrl = getProductMenuUrl(product, productMenu?.text_id);

    return {
      ...item,
      productUrl,
    };
  });

  if (!order) return null;

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={modalClose}
      style={customStyles}
      contentLabel="modal-reorder"
    >
      <ActiveOrder order={order} items={itemsWithUrl} mainDomain={mainDomain} />
      <div className={styles.buttonContainer}>
        <Button onClick={reorderAdd} isFullWidth>
          {t('ReorderModal.Merge cart button')}
        </Button>
        <Button onClick={reorderNew} isFullWidth>
          {t('ReorderModal.NewCartButton')}
        </Button>
      </div>
      <CloseButton onClick={modalClose} />
    </Modal>
  );
}
