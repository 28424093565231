import './Card.scss';
import './CardMobile.scss';
import { PureComponent, createRef } from 'react';
import { cardsEmitter } from 'modules/event-emitters';
import productHelper from 'modules/helpers/product-helper';
import globalStore from 'modules/global-store';
import { withTranslation } from 'react-i18next';
import head from 'lodash/head';
import { withBreakpoints } from 'frontend/hocs';
import { ExternalImage } from '@shared/ui/image';
import { CardWrapper } from '@shared/ui/content-containers';
import { clsx } from 'clsx';
import { withCurrency } from '@entities/countries';
import { connect } from 'react-redux';
import { AvailableInfo } from '@entities/product';
import {
  createSolutionTree,
  isSuperProduct,
  getDefaultProductId,
  getAvailableOptions,
  getProductId,
  createReverseSolutionTree,
} from './superProduct';
import ToCartButton from '../Components/ToCartButton/ToCartButton';
import { openCardModal } from '../../NewCartOrder/Components/CartProductsBlock/utils';
import SuperSelect from './SuperSelect';
import CardIsAction from './CardIsAction';
import Price from './price/Price';
import PureCard from './PureCard/PureCard';
import LinkHandler from './LinkHandler';
import Composition from './composition/Composition';
import controlCardWrapperRef from './controlCardWrapperRef';

class Card extends PureComponent {
  constructor(props) {
    super(props);
    const { product } = this.props;
    this.cardWrapperRef = createRef();
    const superProductId = isSuperProduct(product)
      ? getDefaultProductId(product.product_toppings)
      : null;

    this.state = {
      isHandMoved: false,
      superProductId,
      deliveryValue: '',
      choisenDeliveryType: {},
    };
  }

  componentDidMount() {
    const { product } = this.props;
    const { product_type: { name } = {}, decoration } = product || {};
    const isBigCard = name === 'longcard';

    controlCardWrapperRef(this.cardWrapperRef, decoration);

    if (isBigCard) {
      try {
        const isHandMoved = localStorage.getItem('isHandMoved');
        if (!isHandMoved) {
          localStorage.setItem('isHandMoved', 'yes');
          this.setState({
            isHandMoved: true,
          });
        }
      } catch (e) {
        console.error(e);
      }
    }
    cardsEmitter.addListener('RERENDER', this.reRender);
  }

  componentWillUnmount() {
    cardsEmitter.removeListener('RERENDER', this.reRender);
  }

  /**
   * Объект product не нужно использовать напрямую из props,
   * потому что он может быть super-product'ом, который
   * хранит в себе реальные products. Если где-то будет
   * использоваться products из props'ов, то это приведет
   * к отрисове некрасивых и ненужных super-product'ов
   */
  getConcreateProduct() {
    const { superProductId } = this.state;
    if (superProductId) {
      return globalStore.getProductWithId(superProductId);
    }
    return this.props.product;
  }

  getLink() {
    const { view_type, product: originalProduct } = this.props;
    if (view_type === 'single') return '';

    const selected_category = globalStore
      .get('menu', [])
      .find((c) => c.id === originalProduct.menu_id);
    const link =
      selected_category && selected_category.text_id && originalProduct.text_id
        ? `/menu/${encodeURIComponent(
            selected_category.text_id
          )}/${encodeURIComponent(originalProduct.text_id)}`
        : '#';
    return link;
  }

  reRender = () => this.forceUpdate();

  renderAdditionalPrice = ({ additional_price: additionalPrice = [] }) => {
    if (
      !additionalPrice ||
      !Array.isArray(additionalPrice) ||
      !additionalPrice.length
    )
      return;
    const { title, price } = head(additionalPrice);
    if (!title && !price) return;
    return (
      <div className="card__additional-price">
        <div>{title}</div>
        <div>
          {price}&nbsp;{this.props.currency}
        </div>
      </div>
    );
  };

  renderIsDelivery(product, addComma) {
    const { t } = this.props;
    const {
      is_delivery,
      is_pickup = true,
      decoration: { additional_text_color: textColor = '' } = {},
    } = product;
    if (!is_delivery || !is_pickup) {
      const pickupString =
        !is_delivery && !is_pickup
          ? t('Card.DisableBoth')
          : `${t(
              `Card.${!is_delivery ? 'AvailableOnlyForEx' : 'Delivery only'}`
            )}`;
      const totalString = pickupString + (addComma ? ', ' : ' ');
      return (
        <span className="card__is-delivery" style={{ color: textColor }}>
          {totalString}
        </span>
      );
    }
  }

  renderCartToppings() {
    const { t, cart_item } = this.props;

    if (!cart_item || !cart_item.child) {
      return;
    }
    const toppings = cart_item.child.map((item) => {
      const topping = productHelper.getCartProduct(+item.product_id);
      if (topping) {
        return `${topping.title.trim()}${item.qty > 1 ? ` x ${item.qty}` : ''}`;
      }
    });

    return (
      <span className="card__toppings">
        {`${t('Card.withToppings')}: ${toppings.join(', ')}`}
      </span>
    );
  }

  renderBuyButton(product, child = [], specialStyle, cardType) {
    return (
      <ToCartButton
        product={product}
        position={this.props.position}
        child={child}
        specialStyle={specialStyle}
        cardType={cardType}
      />
    );
  }

  renderSuperCardButtons() {
    const { product } = this.props;
    const { superProductId } = this.state;

    if (!superProductId) {
      return null;
    }

    const tree = createSolutionTree(product.product_toppings);
    const reverseTree = createReverseSolutionTree(product.product_toppings);

    const currentPath = reverseTree[superProductId];
    const availableOptions = getAvailableOptions(tree, currentPath);

    const handleChange = (i) => (newValue) => {
      const newPath = [...currentPath];
      newPath[i] = newValue;
      this.setState({
        superProductId: getProductId(tree, newPath),
      });
    };

    return (
      <div
        className="card___selector"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        {availableOptions.map((options, i) => (
          <SuperSelect
            key={i}
            value={currentPath[i]}
            onChange={handleChange(i)}
            options={options}
          />
        ))}
      </div>
    );
  }

  renderDeliveryChangeButtons() {
    const { product } = this.props;
    const { deliveryValue } = this.state;
    if (!product.prices || Object.keys(product.prices).length < 2) return null;

    const availableOptions = Object.values(product.prices);

    const deliveryTypes = availableOptions.map(({ delivery_type }) =>
      delivery_type === 1 ? 'Доставка' : 'Самовывоз'
    );

    if (!deliveryValue) {
      this.setState({ deliveryValue: 'Доставка' });
      this.setState({ choisenDeliveryType: availableOptions[1] });
    }

    const handleChange = () => (newValue) => {
      this.setState({ deliveryValue: newValue });
      if (deliveryValue === 'Доставка') {
        this.setState({ choisenDeliveryType: availableOptions[1] });
      } else {
        this.setState({ choisenDeliveryType: availableOptions[0] });
      }
    };

    const setOfOptions = [];
    setOfOptions.push(deliveryTypes);

    return (
      <div
        className="card___selector"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        {setOfOptions.map((options, i) => {
          return (
            <SuperSelect
              key={i}
              value={deliveryValue}
              onChange={handleChange(i)}
              options={options}
            />
          );
        })}
      </div>
    );
  }

  // TODO RENAME TO RECENT VIEW
  renderCardWidget(product) {
    const { t, mainDomain, cart_item: { id } = {} } = this.props;
    const img_hash = product.images[0] && product.images[0].filename;
    const {
      product_type: { name } = {},
      customImage,
      available,
      available_from,
      available_to,
    } = product || {};
    let img_url = '';
    if (img_hash) {
      if (name === 'longcard') {
        img_url = img_hash;
      } else img_url = `${img_hash}/120x120`;
    }
    if (customImage) {
      img_url = customImage.replace('/img', '');
    }
    const isAction = product.is_action;
    return (
      <div
        className="card-wrapper--widget"
        onClick={(e) => {
          openCardModal(e, { product, cartItemId: id });
        }}
      >
        <div className="card-for-widget">
          <ExternalImage
            mainDomain={mainDomain}
            className={`card-widget__image ${
              name === 'longcard' ? 'longcard' : ''
            }`}
            src={img_url}
            alt={product.title ? product.title : t('Card.card_widget')}
          />
          <div className="card-widget__main-content">
            <div className="card-widget__name">
              {product.title}
              <div className="card-widget__meta">
                {this.renderIsDelivery(product, isAction)}
                <CardIsAction
                  product={product}
                  addComma={false}
                  haveCustomStyles={false}
                />
              </div>
              <div className="card-widget__meta">
                <AvailableInfo
                  available={available}
                  available_from={available_from}
                  available_to={available_to}
                />
              </div>
            </div>
            <div className="card-widget__options">
              <Price
                product={product}
                cart_item={this.props.cart_item}
                isWidget
                deliveryValue={this.state.deliveryValue}
                choisenDeliveryType={this.state.choisenDeliveryType}
              />
              {this.renderBuyButton(product, [], false)}
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderPrice(priceOptions) {
    return <Price {...priceOptions} />;
  }

  renderComposition(product) {
    const { view_type, cart_item: { child = [] } = {} } = this.props;
    if (view_type === 'recomend') return null;
    const isToppings = !!(
      product.toppings &&
      product.toppings.length &&
      product.max_toppings !== 0
    );
    const isPureProductWithToppings = isToppings && !child.length;
    if (view_type === 'grid' || !isToppings || isPureProductWithToppings)
      return <Composition product={product} />;
    return this.renderCartToppings();
  }

  render() {
    const {
      view_type,
      isMobile,
      product: originalProduct,
      cart_item,
      cart_item: { child = [] } = {},
    } = this.props;
    const { isHandMoved, deliveryValue, choisenDeliveryType } = this.state;
    const product = this.getConcreateProduct();
    if (!product) {
      return null;
    }

    if (view_type === 'widget') return this.renderCardWidget(product);

    const isDelivery = !isMobile
      ? this.renderIsDelivery(
          product,
          (product.composition && product.composition.length) ||
            product.isAction
        )
      : this.renderIsDelivery(product, view_type === 'single');

    const { specialStyle, product_type: { name } = {} } = product;

    const options = {
      isDelivery,
      product,
      view_type,
      superCardButtons: this.renderSuperCardButtons(),
      deliveryChangeButtons: this.renderDeliveryChangeButtons(),
      additionalPrice: this.renderAdditionalPrice(product),
      price: this.renderPrice({
        product,
        cart_item,
        deliveryValue,
        choisenDeliveryType,
      }),
      buyBtn: this.renderBuyButton(product, child, specialStyle, view_type),
      composition: this.renderComposition(product),
    };

    const isBlack = specialStyle === 'Black';
    const isRed = specialStyle === 'Red';

    const cardView = clsx(`card--${view_type}`, {
      'card--black': isBlack,
      'card--common-1': isRed,
    });

    const cardWrapperStyles = clsx(`card-wrapper--${view_type}`, {
      bigCard: name === 'longcard',
      moveHand: isHandMoved,
    });

    if (['single', 'grid', 'recomend'].includes(view_type)) {
      return (
        <CardWrapper className={cardWrapperStyles} ref={this.cardWrapperRef}>
          <LinkHandler
            link={this.getLink()}
            product={originalProduct}
            cartItemId={cart_item}
            className={cardView}
          >
            <PureCard {...options} />
          </LinkHandler>
        </CardWrapper>
      );
    }

    return null;
  }
}
const mapStateToProps = ({ countries }) => ({
  mainDomain: countries.country.main_domain,
});

export default connect(mapStateToProps)(
  withTranslation()(withBreakpoints(withCurrency(Card)))
);
