import type { IOrder } from '@entities/order';
import OrderInfo from 'src/frontend/Components/Order/Info/OrderInfo';
import { useResetOrder } from '../../lib/reset-order-on-mount';

export function OrderInfoContainer({
  order,
  order_salt,
}: {
  order: IOrder;
  order_salt: string;
}) {
  useResetOrder();

  return <OrderInfo order={order} order_salt={order_salt} />;
}
