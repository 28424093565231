import '../Checkbox.scss';
import { Component } from 'react';
import autobind from 'autobind-decorator';
import { withCheckCountries } from '@entities/countries';
import { clsx } from 'clsx';

class FilterCheckbox extends Component {
  @autobind
  toggle() {
    let { checked } = this.props;
    const { name = 'defaultName' } = this.props;
    checked = !checked;
    if (this.props.onToggle) {
      this.props.onToggle({ checked, name });
    }
  }

  render() {
    let { checked = false } = this.props;
    const { title = '' } = this.props;
    checked = !!checked;
    const isUa = this.props.isUkraine;
    return (
      <div className="checkbox">
        <span
          style={{ marginTop: 0 }}
          className={clsx(`checkbox__${checked ? 'checked' : 'none'}`, {
            ua: isUa,
          })}
          onClick={this.toggle}
        >
          {title}
        </span>
      </div>
    );
  }
}

export default withCheckCountries(FilterCheckbox);
