import { animated, useSpring } from '@react-spring/web';
import { clsx } from 'clsx';
import { memo, PropsWithChildren, useEffect, useRef, useState } from 'react';
import styles from './accordion.module.scss';

function Accordion({
  show,
  className = '',
  children,
}: PropsWithChildren<{
  show: boolean;
  className?: string;
}>) {
  const [contentMaxHeight, setContentMaxHeight] = useState(0);
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    setContentMaxHeight(ref.current?.scrollHeight || 0);
  }, [ref, children]);

  const style = useSpring({
    opacity: show ? 1 : 0,
    maxHeight: show ? `${contentMaxHeight}px` : '0px',
  });

  const accordionClass = clsx(styles.accordion, { [className]: className });
  return (
    <animated.div ref={ref} className={accordionClass} style={style}>
      {children}
    </animated.div>
  );
}

export default memo(Accordion);
