import { useTranslation } from 'react-i18next';
import styles from './vacancyContactsLine.module.scss';

export function VacancyContactsLine({
  vacancyEmail,
}: {
  vacancyEmail: string;
}) {
  const { t } = useTranslation();

  return (
    <div className={styles.vacancyContactsLine}>
      {t('VacancyContactsLine.Vacancies-contacts')}{' '}
      <a className={styles.link} href={`mailto:${vacancyEmail}`}>
        {vacancyEmail}
      </a>
    </div>
  );
}
